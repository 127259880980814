import React from 'react';
import { Navigate, Routes, useLocation, Route } from "react-router-dom";
import { useAuth } from '../context/auth';
import { Home } from '../pages/Home';
import { InternalProfile } from '../pages/InternalProfile';
import { Member } from '../pages/Member';
import { NotFound } from '../pages/NotFound';
import { PropertyDetails } from '../pages/PropertyDetails';
import RegisterNewProperty from '../pages/RegisterNewProperty';
import { SearchResult } from '../pages/SearchResult';
import { ListBlog } from '../pages/List-Blog';
import { DetailsBlog } from '../pages/Details-Blog';
import { PropertyDetailsShare } from '../pages/PropertyDetailsShare';
import { SearchResultCategory } from '../pages/SearchResultCategory';
import { SearchResultConstructor } from '../pages/SearchResultConstructor';
import { EnterpriseDetails } from '../pages/EnterpriseDetails';
import { EnterpriseDetailsShare } from '../pages/EnterpriseDetailsShare';
import { PersonalCard } from '../pages/PersonalCard';
import { Kanban } from '../pages/Kanban';
import { ResetPassword } from '../pages/ResetPassword';
import { ForgottenPassword } from '../pages/ForgottenPassword';

// imports para o Lazy Load
// const LazyHome = lazy(() => import("../pages/Home"));
// const LazyPropertyDetails = lazy(() => import("../pages/PropertyDetails"));
// const LazyProfile = lazy(() => import("../pages/Member"));
// const LazyNotFound = lazy(() => import("../pages/NotFound"));



export const RoutesApp: React.FC = () => {

    let { user } = useAuth()

    function RequireAuth({ children }: { children: JSX.Element }) {
        let location = useLocation();

        if (!user) {
            return <Navigate to='/' state={{ from: location }} replace />
        }
        return children
    }

    return (
        <Routes>
            <Route
                path="/imovel/:id"
                element={<PropertyDetails />}
            />
            <Route
                path="/perfil/:idcorretor/imovel/:id"
                element={<PropertyDetailsShare />}
            />
            <Route
                path="/empreendimento/:id"
                element={<EnterpriseDetails />}
            />
            <Route
                path="perfil/:idcorretor/empreendimento/:id"
                element={<EnterpriseDetailsShare />}
            />
            <Route
                path="/perfil/:id"
                element={<Member />}
            />
            <Route
                path="/cartao-pessoal/:id"
                element={<PersonalCard />}
            />
            <Route
                path="/"
                element={<Home />}
            />
            <Route
                path="/resultado-pesquisa"
                element={<SearchResult />}
            />
            <Route
                path="/imovel/categoria/:id"
                element={<SearchResultCategory />}
            />
            <Route
                path="/imovel/construtora/:id"
                element={<SearchResultConstructor />}
            />
            <Route
                path="/blog-noticias"
                element={<ListBlog />}
            />
            <Route
                path="/blog-noticias/:id"
                element={<DetailsBlog />}
            />
            <Route
                path="/perfil-interno"
                element={
                <RequireAuth>
                    <InternalProfile />
                </RequireAuth>}
            />
            <Route
                path="/redefinir-senha/:uuid"
                element={<ResetPassword />}
            />
            <Route
                path="/cadastrar-se/:uuid"
                element={<ResetPassword />}
            />
            <Route
                path="/esqueci-minha-senha/"
                element={<ForgottenPassword />}
            />
            <Route
                path="/primeiro-acesso/"
                element={<ForgottenPassword />}
            />
            <Route
                path="/kanban"
                element={<Kanban />}
            />
            <Route
                path="/cadastrar-imovel"
                element={
                    <RequireAuth>
                        <RegisterNewProperty />
                    </RequireAuth>
                }
            />
            <Route
                path="*"
                element={<NotFound />}
            />
        </Routes>

        // Routes com otimização de rotas - Lazy Load
        // <Routes>
        //     <Route 
        //         path="/"
        //         element={
        //             <Suspense>
        //                 <LazyHome />
        //             </Suspense>
        //         }
        //     />
        //     <Route 
        //         path="/imovel/:id"
        //         element={
        //             <Suspense>
        //                 <LazyPropertyDetails />
        //             </Suspense>
        //         }
        //     />
        //     <Route 
        //         path="/perfil"
        //         element={
        //             <Suspense>
        //                 <LazyProfile />
        //             </Suspense>
        //         }
        //     />
        //     <Route 
        //         path="*"
        //         element={
        //             <Suspense>
        //                 <LazyNotFound />
        //             </Suspense>
        //         }
        //     />
        // </Routes>
    );
}