
import {
  Container,
} from './styles';

interface IPropButton {
    label: string;
    width?: string;
    height?: string;
    color?: string;
    bgcolor?: string;
    onclick?: () => void;
    mb?:string;
    disable?: boolean;
}

export function Button({ label, width, height, color, bgcolor, onclick, mb, disable }: IPropButton){
  return(
    <Container 
        onClick={onclick} 
        w={width} 
        h={height} 
        color={color}
        bgcolor={bgcolor}
        mb={mb}
    >
        <button disabled={disable}>{label}</button>
    </Container>
  );
}