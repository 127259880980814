import { Container, Content, ContentFinally } from "./styles";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaLinkedin, FaMapMarkerAlt, FaRegCopyright, FaSkype } from 'react-icons/fa';
import { GoToTop } from "../../GoToTop";

export function Footer() {
    return (
        <Container>
            <Content>
                <div className="col-1">
                    <img src={'https://portal-nogueira.s3.us-east-2.amazonaws.com/986f1fdc7188a6168ac1-logo-hub-nogueira.png'} alt="Logomarca Nogueira" />
                    {/* <p className="endereco">Rua Antônio Lumack do Monte, 128 - sala 1401 Recife-PE</p> */}
                    <p className="contato-tel"><strong>Contato telefônico: 81 3019.3333</strong></p>
                </div>
                <div className="col-2">
                    <h3>Links rápidos</h3>
                    <div className="content-links">
                        <Link className="links" to='#'>Termos de uso</Link>
                        <Link className="links" to='#'>Política de privacidade</Link>
                        <Link className="links" to='#'>Atendimento</Link>
                        <Link className="links" to='#'>Ca</Link>
                        <Link className="links" to='#'>FAQs</Link>
                    </div>
                </div>
                <div className="col-3">
                    <h3>Horário de funcionamento</h3>
                    <div className="content-col-3">
                        <p className="times">Seg. a Sex. - 8:00 às 20:00</p>
                        <p className="times">Sáb. - 08:00 as 16:00</p>
                        <p className="times">Dom. - 08:00 às 14:00</p>
                    </div>
                </div>
                <div className="col-4">
                    <h3>Endereços</h3>
                    <div className="content-col-4">
                        <div className="icon-and-text">
                            <FaMapMarkerAlt className="icon-1" />
                            <div className="infos-address">
                                <p className="title">Hub Nogueira Center II</p>
                                <p>Rua Antônio Lumack do Monte, 96 - sala 1201</p>
                                <p>Empresarial Center II - Boa Viagem - Recife/PE</p>
                            </div>
                        </div>
                        <div className="icon-and-text">
                            <FaMapMarkerAlt className="icon-2" />
                            <div className="infos-address">
                                <p className="title">Hub Nogueira Center III</p>
                                <p>Rua Antônio Lumack do Monte, 128 - sala 1401</p>
                                <p>Empresarial Center III - Boa Viagem - Recife/PE</p>
                            </div>
                        </div>

                    </div>
                </div>
            </Content>
            <ContentFinally>
                <div className="icons">
                    <FaFacebook cursor='pointer' />
                    <FaSkype cursor='pointer' />
                    <FaLinkedin cursor='pointer' />
                    <FaInstagram cursor='pointer' />
                </div>
                <div className="direitos-reservados">
                    <FaRegCopyright color="#fff" />
                    <p>{`${new Date().getFullYear()}`}. Todos os direitos reservados</p>
                </div>
            </ContentFinally>
            <GoToTop />
        </Container>
    )
}