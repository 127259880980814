import styled, { keyframes } from "styled-components";


const animation = keyframes`
    to {
        background-position-x: -20%;
    }
`;

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;


export const Container = styled.div`

    div.content_wrapper {

        width: 85vw;
        margin: 0px 7.5vw;

        h2.page_title {
            padding-bottom: 8px;
            margin: 34px 0px;
            border-bottom: 2px dashed #dadada;
        }
        
        div#kanban_wrapper {
            overflow-x: auto;

            div#div_kanban {
                /* background-color: gray; */
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                grid-gap: 16px;
                margin-bottom: 64px;
                min-width: 1250px;

                @media (max-width: 1300px) {
                    grid-template-columns: 1fr 1fr 1fr;
                    overflow-x: unset;
                    min-width: unset;  
                }

                @media (max-width: 700px) {
                    grid-template-columns: 1fr;
                }

                div.div_kanban_collum {

                    h3 {
                        display: flex;
                        justify-content: space-between;
                        padding: 6px 12px;
                        border-radius: 5px;
                        color: white;
                        text-shadow: 1px 1px 12px rgba(0,0,0,.25);
                        /* margin-bottom: 12px; */
                    }

                    ul.ul_kanban_collum {
                        list-style: none;
                        background-color: rgb(238 238 238);
                        border-radius: 8px;
                        margin: 0;
                        /* padding: 7px 5px; */
                        height: 700px;
                        overflow-x: auto;

                        li.card {
                            cursor: pointer;
                            animation: .4s ${fadeIn} ease-out;
                            width: calc(100% - 10px);
                            display: flex;
                            background: white;
                            border-radius: 4px;
                            padding: 12px 18px 12px 6px;
                            gap: 7px;
                            min-height: 120px;
                            transition: all .15s;
                            margin: 8px 5px;

                            :hover {
                                box-shadow: 1px 1px 12px rgba(0,0,0,.15);
                            }

                            div.basel {
                                background: red;
                                min-height: 50px;
                                border-radius: 3px;
                                width: 5px;
                            }

                            div.li_content {
                                display: block;
                                position: relative;
                                padding-bottom: 30px;
                                width: 100%;
                                h6 {
                                    font-size: 14px;
                                }
                                div.building_description {
                                    color: #5c5c5c;
                                    font-size: 14px;
                                }
                                div.value_wrapper {
                                    position: absolute;
                                    width: 100%;
                                    display: flex;
                                    justify-content: space-between;
                                    bottom: 0;
                                    right: 0;
                                    white-space: nowrap;
                                }
                            }
                        }


                        div.loading {
                        background: linear-gradient(100deg, rgba(255, 255, 255, .8) 40%, rgba(255, 255, 255, .60) 50%, rgba(255, 255, 255, .8) 60%),  rgb(210, 210, 210);
                        background-size: 200% 100%;
                        background-position-x: 180%;
                        animation: 1s ${animation} ease-in-out infinite;
                        min-height: 4rem;
                        border-radius: 4px;
                        height: 100%;
                        width: 100%;
                        border-radius: 6px;
                        }
                    }
                }
            }
        }
    }

`;
