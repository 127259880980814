import { useEffect, useState } from "react";
import { CardStyled } from "./style";
import { Header, ScrollProps } from "../../components/Header";
import BgImage from '../../assets/bg-personal-card.svg';
import TopImage from '../../assets/logo-hub-nogueira-white.png';
import { useNavigate } from "react-router-dom";
import { FaEnvelope, FaGlobe, FaInstagram, FaMapMarkerAlt, FaPhone, FaWhatsapp } from "react-icons/fa";
import { BsGlobe2 } from "react-icons/bs";
import { GoMail } from "react-icons/go";
import { useAuth } from "../../context/auth";
import { wait } from "@testing-library/user-event/dist/utils";
import { LoadSpinner } from "../../components/LoadSpinner";

interface IUserData {
    nome: string;
    email_contato: string;
    telefone_01: string;
    telefone_02: string;
    instagram: string;
}

export function PersonalCard() {

    const { user, api } = useAuth();

    const [userData, setUserData] = useState({} as IUserData);


    useEffect(() => {
        api.get('portal/corretor/' + user.id_corretor).then((resp) => {
            console.log(resp.data);
            setUserData(resp.data)
        })
    }, [])

    return (
        <div>
            {userData.nome ?
                <CardStyled>
                    <img src={BgImage} className="bg-img" alt="Background" />
                    <div className="content">

                        <div className="header">
                            <img src={TopImage} alt="" className="img-header" />
                        </div>

                        <div className="user_info">
                            <div className="tab left-tab"></div>
                            <div className="user_data">
                                <div className="name">
                                    <span className="frist_name">{userData.nome.toUpperCase().split(' ')[0]} </span>
                                    <span className="last_name">{userData.nome.toUpperCase().split(' ').length > 1 ? userData.nome.toUpperCase().split(' ')[userData.nome.toUpperCase().split(' ').length - 1] : ''} </span>
                                </div>
                                <div className="function">
                                    DIRETOR DE VENDAS
                                </div>
                                <div className="creci" >
                                    CRECI 14027
                                </div>
                            </div>
                            <div className="tab right-tab"></div>
                        </div>

                        <div className="social_grid">
                            <a className="dark" target="_blank" href={`https://api.whatsapp.com/send?phone=+55${userData.telefone_01.replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '').replaceAll('-', '')}`} ><FaWhatsapp /></a>
                            <a className="light" target="_blank" href={`tel:+55${userData.telefone_01.replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '').replaceAll('-', '')}`} ><FaPhone /></a>
                            <a className="dark" target="_blank" href={`mailto:${userData.email_contato}`} ><GoMail /></a>
                            <a className="light" target="_blank" href={`${userData.instagram}`} ><FaInstagram /></a>
                            <a className="dark" target="_blank" href={`https://portal.hubnogueira.com.br/perfil/${user.id_corretor}`} ><BsGlobe2 /></a>
                            <a className="light" target="_blank" href="https://goo.gl/maps/qCFfiMSpVVc57xeX8" ><FaMapMarkerAlt /></a>
                        </div>

                        <div className="footer">
                            Toque nos ícones para interagir
                        </div>

                    </div>
                </CardStyled>
                :
                <LoadSpinner />
            }

        </div>
    )
}