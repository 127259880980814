import styled from 'styled-components';

// height 80px
// div logo and div buttons



export const Container = styled.div`
  
  position: sticky;
  top: 0;
  z-index: 20;
  width: 100%;
  transition: 1s ease;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0,0,0, .1);
    

  .hidden {
    top:-80px !important;

    @media screen and (max-width:800px){
      top:auto !important;
    }
  }
  
`;

export const ContentWrapper = styled.div`
  .header-mobile {
    display: none;
    
    .log-mobile {
      /* display: none; */
      /* width: 100%; */
      cursor: pointer;

      img {
        width: 90px;
      }
    }

    button {
      border: none;
      background-color: transparent;
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      color: var(--background-button-component);

      .icon-button-search-mobile {
        color: var(--background-button-component);
        margin: 0;
      }
    }

    .right-mobile {
      /* display: none; */
      /* position: relative; */
        /* padding-top: .8rem; */
        z-index: 1;

        .user-logged {
          display: flex;
          align-items: center;
          justify-content: center;
          p{
            margin:0;
          }
          .no-avatar-logged-mobile {
            color: var(--background-button-component);
            font-size: 2rem;
            margin-right: 0.5rem;
          }
          .avatar-logged-mobile {
            width: 40px;
            height: 40px;
            border-radius: 50px;
            margin-right: 0.3rem;
          }
          .text-login{
            margin: 0;
            color: var(--color-placeholder-input);
            span {
              font-weight: bold;
              color: var(--background-button-component);
            }
          }
          .logout-mobile {
            margin-left: 0.1rem;
            margin-right: 0.3rem;
            font-size: 1.3rem;
            cursor: pointer;
          }
        }

        .user-no-logged-mobile {
          background-color: var(--background-button-component);
          height: 35px;
          width: 90px;
          border-radius: 50px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.1rem;
          
          transition: 0.3s;

          :hover {
            filter: brightness(0.7);
          }

          .avatar-no-logged-mobile {
            color: #fff;
            font-size: 1.5rem;
          }

          .title-login-mobile {
            color: #fff;
            margin: 0 0 0 0.6rem;
          }

        }
    }
  
  

      

      p {
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        gap: 0.3rem;

        span {
          display: flex;
          align-items: center;
          font-weight: bold;
          color: var(--background-button-component);
          cursor: pointer;
          transition: 0.3s;

          &:hover {
            font-size: 1rem;
          }

          .icon-login {
            font-size:1.2rem;
            &:hover {
            font-size: 1.5rem;
          }
          }
        }
      }
  }
  
  .header-wrapper {
    display: flex;
    order: 1;
    justify-content: space-between;
    align-items: center;
    gap: .5rem;
    transition: .2s;
    width: 100%;
    height: 80px;
    padding: 0 0 0 2rem;
    margin: 0 auto;
    border-top: 1px solid rgba(0,0,0, .1);
    border-bottom: 1px solid rgba(0,0,0, .1);

    @media screen and (max-width: 1132px) {
      padding: 0 0 0 0;
      height: auto;
    } 

    .log {
      width: 150px;
      /* height: 39px; */

      img {
        width: 100%;
        cursor: pointer;
      }
      
    }

    .left-mobile {
      display: none;
      width: 100%;
      transition: .2s;

      .selects-mobile {
        margin-top: 5px;
        margin-bottom: 5px;
        width: 100%;
        .div-select-operation-mobile {
          border: 1px solid rgba(0,0,0, .1);
          box-shadow: 0 4px 8px rgba(0,0,0, .08);
          border-radius: 8px;
          width: 100%;
          .select-operation-mobile{
            width: 100%;
          }
        }
        .div-select-category-mobile {
          border: 1px solid rgba(0,0,0, .1);
          box-shadow: 0 4px 8px rgba(0,0,0, .08);
          border-radius: 8px;
          margin-top: 0.5rem;
          width: 100%;
          .select-category-mobile{
            width: 100%;
          }
        }
        .input-search-mobile {
          margin-top: 0.5rem;
          padding: 0.5rem;
          width: 100%;
          border: 1px solid rgba(0,0,0, .1);
          box-shadow: 0 4px 8px rgba(0,0,0, .08);
          border-radius: 8px;
        }
        .content-div-buttons {
          display: flex;
          flex-direction: column;
          margin-top: 0.5rem;
          width: 100%;
          .search-button {
            background-color: var(--background-button-component);
            font-size: 1rem;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            padding: 0.5rem;
            border-radius: 8px;
          }
          .advanced-button {
            margin-top: 0.5rem;
            border: none;
            background: transparent;
            font-size: 0.8rem;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            padding: 0.3rem;
            border-radius: 8px;
            .icon-advanced-button {
              font-size: 0.95rem;
            }
          }
        }
      }

      @media screen and (max-width: 1132px) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        padding: 0.3rem;

        /* .selects-mobile {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        } */

        
      }
    }
    
    .left {
      position: relative;
      display: flex;
      justify-content: flex-end;
      width: 600px;

      @media screen and (max-width: 1132px) {
        display: none;
        height: 0;
      }  

      @media screen and (max-width: 760px) {
        display: none;
        height: 0;
      } 
      
      input {
        position: relative;
        height: 48px;
        width: 100%;
        padding: 0 3.7rem 0 1rem;
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
        border: 1px solid rgba(0,0,0, .1);
        box-shadow: 0 4px 8px rgba(0,0,0, .08);
        z-index: 10;

        @media screen and (max-width: 760px) {
          padding-right: 0;
        } 
      }

      .div-select-operation {
        padding: 0 0.5rem 0 0;
        border: 1px solid rgba(0,0,0, .1);
        box-shadow: 0 4px 8px rgba(0,0,0, .08);
        border-top-left-radius: 2rem;
        border-bottom-left-radius: 2rem;
        z-index: 10;
        height:48px;
        .select-operation {
          /* height:46px; */
          width: 150px;
          border: none;
          /* border-top-left-radius: 2rem;
          border-bottom-left-radius: 2rem; */
          padding: 0 0 0 0.5rem;
          margin-top: -2px;
          color: var(--color-font-footer);
        }
      }

      .div-select-category {
        padding: 0 0.5rem 0 0;
        border: 1px solid rgba(0,0,0, .1);
        box-shadow: 0 4px 8px rgba(0,0,0, .08);
        z-index: 10;
        height:48px;
        .select-category {
          /* height:46px; */
          margin-top: 7px;
          width: 180px;
          border: none;
          padding: 0 0 0 0.5rem;
          color: var(--color-font-footer);
        }
      }
      

      .icon-erase-text {
        position: absolute;
        right: 2.6rem;
        top: 0.9rem;
        z-index: 10;
        cursor: pointer;
        color: var(--color-placeholder-input);
      }
      
      .icon {
        position: absolute;
        right: 1rem;
        top: 0.8rem;
        z-index: 10;
        cursor: pointer;

        @media screen and (max-width: 760px) {
          right: 4rem;
        } 
      }
    }
    
    .right {
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      /* padding-top: 0.8rem; */
      z-index: 1;

      .user-logged {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 2rem;
        p{
          margin:0;
        }
        .avatar-logged {
          color: var(--background-button-component);
          font-size: 2.2rem;
          margin-right: 0.5rem;
          cursor: pointer;
        }
        .img-logged {
          border-radius: 50%;
          width: 40px;
          height: 40px;
          object-fit: cover;
          margin-right: 0.5rem;
          cursor: pointer;
        }
        .text-login{
          font-family: 'Poppins', sans-serif;
          margin: 0;
          color: var(--color-placeholder-input);
          span {
            font-weight: bold;
            color: var(--background-button-component);
          }
        }
        .logout {
          margin-left: 0.7rem;
          cursor: pointer;
        }
      }

      .user-no-logged {
        background-color: var(--background-button-component);
        height: 40px;
        width: 150px;
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-left: 1rem;
        transition: 0.3s;

        :hover {
          filter: brightness(0.7);
        }

        .avatar-no-logged {
          color: #fff;
          font-size: 1.5rem;
        }

        .title-login {
          color: #fff;
          margin: 0 0 0 0.8rem;
        }

      }

      p {
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        gap: 0.3rem;

        span {
          display: flex;
          align-items: center;
          font-weight: bold;
          color: var(--background-button-component);
          cursor: pointer;
          transition: 0.3s;

          &:hover {
            font-size: 1rem;
          }

          .icon-login {
            font-size:1.2rem;
            &:hover {
            font-size: 1.5rem;
          }
          }
        }
      }
    }
  }

  .left .btn-filter {
    position: absolute;
    right: -5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: .5rem;
    width: 120px;
    height: 48px;
    padding-right: .8rem;
    margin-bottom: 1rem;
    border-top-right-radius: 1.9rem;
    border-bottom-right-radius: 1.9rem;
    border: 1px solid rgba(0,0,0, .2);
    cursor: pointer;
    z-index: 1;

    @media screen and (max-width: 760px) {
      margin-right: 3rem;
    }

    h4 {
      font-size: .8rem;
      padding-top: .5rem;
    }
  }
  
  @media screen and (max-width: 1132px) {
      padding: 0 5px;
      .header-wrapper .right { 
        display: none; 
      }
  }

  @media screen and (max-width: 1132px) {
    .header-mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      
    }

    .log {
      display: none;
    }
    
  }

  @media screen and (max-width: 760px) {
    padding-top: .6rem;

    .log-mobile {
      display: flex;
      justify-content: flex-start;
      margin: 0 0 0 0.5rem;

      p {
        font-size: 2rem;
        margin-bottom: 0;
        padding: .5rem; 
      }
    }

    .header-wrapper {
      border-top: 0;
      
      .log {
        display: none;
      }
    }
  }

`;

export const ContentWrapperFilters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 4%;
  margin: 0 auto;

  .filters-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 78px;
  
    .filters-icons {
      width: 100%;
      height: auto;

      .content-slide {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        gap: 2rem;
        margin-left:3.5rem;
        width: 100%;
      }
      
      
      .filter-single {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding-top: 1.5rem;
        padding-bottom: 1rem;
        cursor: pointer;

        &:hover::after, &.active::after{
          content: '';
          position: absolute;
          left: 0;
          bottom: -3px;
          width: 100%;
          height: 3px;
          background-color: #444;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }
        &:hover::after {
          background-color: #888;
        }
        &.active::after {
          background-color: #444;
        }

        span {
          font-size: 1.4rem;
        }

        p {
          font-size: .9rem;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
      padding: 0 4%;
  }
`;

export const ContentModal = styled.div`
  max-height: 65vh;
  padding: 0 1rem 0 0;
  overflow-y: auto;
  border-top: 1px solid rgba(0,0,0, .1);
  border-bottom: 1px solid rgba(0,0,0, .1);
  /* background-color: gray; */

  /* @media (max-width: 1370px){
    max-height: 450px;
  } */

  .box-input-ranger,
  .objective,
  .categories,
  .convenience,
  .rooms {
    padding: 2rem 0 2rem 0;
    border-bottom: 1px solid rgba(0,0,0, .1);
  }

  .box-input-ranger {
    .range {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: .5rem;
      width: 100%;
      margin-bottom: 1rem;

      input {
        width: 160px;
        padding: 5px 8px;
        text-align: center;
        border-radius: 8px;
        border: 1px solid rgba(0,0,0, .2);
      }

      /* input {
        width: 70%;
        cursor: pointer;
        margin-top: -1rem;
      } */

      .range-slider {
        width: 70%;
        /* margin-top: -0.45rem; */
      }
      
      @media screen and (max-width:800px){
        
          flex-direction: column;
          padding: 0 0.5rem;
        
          input {
            width: 100%;
          }

          .range-slider {
            width: 100%;
          }
      }
    }
  }

  .objective {
    width: 100%;

    .div-header-modal-advanced{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      h2{
        margin: 0;
      }
    
      button {
        border: 1px solid rgba(0,0,0, .2);
        background: transparent;
        font-size: 0.8rem;
        padding: 0.2rem 0.5rem;
        border-radius: 8px;
        cursor: pointer;
        color: var(--background-button-component);
      }
    }

    .content-buttons {
      display: flex;
      gap: 1rem;
      width: 100%;

      .button-active {
        width: 100%;
        border: none;
        padding: 0.5rem;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s;
        background-color: var(--background-button-component);
        color: #fff;
      }

      .button-inactive {
        width: 100%;
        border: none;
        padding: 0.5rem;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          background-color: var(--background-button-component);
          color: #fff;
        }
      }
    }
    
  }

  .categories .box-cat-single {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;

    @media screen and (max-width:800px){
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .convenience .box-checkbox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: .8rem;


    .box-checkbox-single {
      display: flex;
      align-items: center;
      gap: .5rem;
      label {
        font-size: 1rem;
      }
      
      input[type=checkbox] {
        width: 22px;
        height: 22px;
        cursor: pointer;
      }
    }
  }

  .type {

    h2 {
      margin-top: 1rem;
    }

    .select-type {
      width: 100%;
      border: 1px solid rgba(0,0,0, .2);
      height: 3rem;
      border-radius: 8px;
      padding: 0.5rem;
      margin-bottom: 1rem;
      transition: all.3s;
      
      &:hover {
        background: rgba(0,0,0,.09);
      }

      .select-type-select {
        font-size: 1rem;
        border: none;
        width: 100%;
        color: var(--color-font-footer);
        background-color: transparent;
      }
    }

    border-bottom: 1px solid rgba(0,0,0, .1);
  }

  .address {
    
    h2 {
      margin-top: 1rem;
    }

    .google {
      font-family: 'Poppins', sans-serif;
    }

    border-bottom: 1px solid rgba(0,0,0, .1);
  }

  .rooms .room-single {
    margin-bottom: 1rem;

    h4 {
      font-weight: 400;
    }

    .bullets .bullet-single-text {
      padding: 5px 12px;
      border-radius: 1rem;
      border: 1px solid rgba(0,0,0, .4);
    }
  }
  .rooms .room-single .bullets {
    display: flex;
    gap: 1rem;

    .bullet-single {
      display: grid;
      place-items: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 1px solid rgba(0,0,0, .4);
    }
  }

  .bullets {
    display: flex;
    justify-content: space-between;
  }

  .bullets .bullet-single-text, 
  .bullets .bullet-single {
    transition: all .4s ease;
    cursor: pointer;

    &:hover {
      color: #fff;
      background-color: #0D2244;
      box-shadow: 0px 2px 4px rgba(0,0,0, .5);
    }

    &.clicked {
      color: #fff;
      background-color: #0D2244;
      box-shadow: 0px 2px 4px rgba(0,0,0, .5);
    }
  }
`;

export const ContentButtonsModalFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 1rem;

  .button-cancel {
    border: 1px solid gray;
    padding: 0.5rem;
    background-color: transparent;
    border-radius: 6px;
    cursor: pointer;
    transition: all.3s;

    &:hover {
      background-color: var(--background-footer);
    }
  }

  .button-accept {
    padding: 0.5rem;
    border: 1px solid var(--background-button-component);
    background-color: var(--background-button-component);
    border-radius: 6px;
    color: white;
    cursor: pointer;
    font-weight: 600;
    transition: all.3s;

    &:hover {
      filter: brightness(1.5);
    }
  }
`;

export const ContentModalGray = styled.div`
  .content-modal-gray {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap:1rem;

    .grid-1 {
      .content-buttons {
        display: flex;
        flex-direction: column;
        gap:0.65rem;
        button {
          width: 100%;
          border-radius: 50px;
          padding: 0.3rem;
          border: none;
          cursor: pointer;
          transition: 0.3s;
          background-color: var(--color-placeholder-input);
        }

        button:focus {
          background-color: #fff;
          color: var(--color-placeholder-input);
        }
      }
    }

    .grid-2 {
      select {
        background-color: transparent;
        border-radius: 50px;
        border: 1px solid #fff;
        padding-right: 2rem;
        width: 100%;
      }
      .title-2 {
        margin-top: 1rem;
        font-weight: 600;
      }
    }

    .grid-3 {
      input{
        margin-top: 0.3rem;
      }
      .title-2 {
        margin-top: 1rem;
        font-weight: 600;
      }
    }

    .grid-4 {
      display: grid;
      grid-template-columns: repeat(2,1fr);
      gap: 0.5rem;
      
      .content-1 {
        
        p {
          margin: 0.5rem 0;
          text-align: left;
          font-size: 0.9rem;
        }
      }

      .content-2 {
        .buttons-flex {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0.4rem 0;
          gap: 0.5rem;
          p {
            margin: 0;
          }
          .add {
            cursor: pointer;
          }
        }
      }
    }

    .title {
      font-weight: 600;
    }
  }

  .div-button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 1rem;

    button {
      background-color: var(--background-button-component);
      color: white;
      padding: 0.6rem;
      border-radius: 6px;
      font-size: 0.8rem;
      border:none;
      cursor: pointer;
      transition: .3s;

      &:hover {
        filter: brightness(0.5);
      }
    }
  }
  
`;

export const ContentModalGoogle = styled.div`
  padding: .9rem .5rem;
  text-align: center;
  padding-bottom: 34px;
  .title-logout {
    font-size: 0.9rem;
  }

  .input-login {
    width: 100%;
    height: 2.5rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;
    /* border: 0.2px solid var(--gray-one); */
    background-color: var(--background-footer);
    border:none;
    font-size: .9rem;
    padding: 0 0 0 .5rem;
  }

  .input-login-div {
    width: 100%;
    height: 2.5rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;
    /* border: 0.2px solid var(--gray-one); */
    background-color: var(--background-footer);
    border:none;
    font-size: .9rem;
    padding: 0 0 0 .5rem;
    display: flex;
    flex-direction: row;
  }

  .input-div-login {
    border: none;
    background-color: transparent;
    width: 80%;
    font-size: .9rem;
  }

  .button-eye{
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    background-color: transparent;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .button-login {
    width: 100%;
    height: 2.5rem;
    margin-bottom: 0rem;
    border-radius: 5px;
    border: none;
    font-weight: 600;
    background-color: var(--background-button-component);
    color:var(--bg-color);
    cursor: pointer;
    transition: 0.3s;

      &:hover {
        filter:brightness(0.8);
      }
  }

  .form-group {
    display: block;
    text-align: left;
    margin-bottom: 2px;
  }

  .content-button {
    display: flex;
    justify-content: center;

    button {
      margin: 12px 0px !important;
      padding: 0.5rem 1.5rem;
      border: none;
      background-color: var(--background-button-component);
      color: var(--bg-color);
      font-weight: 600;
      font-family: 'Poppins', sans-serif;
      border-radius: 6px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        filter:brightness(0.8);
      }
    }
  }

  div.other_option {
    span {
      a {
        color: #0E2244;
        :hover {
          text-decoration: underline;
        }
      }
    }
  }

  iframe {
    width: 100% !important;
  }

  img {
    width: 170px;
    margin: .4rem 0rem 1.4rem;
  }
`;