import { Container } from "./styles";
import img from '../../assets/jpg/h10.jpg';
import { AiOutlineCalendar } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export function CardBlog(){

    const navigate = useNavigate()

    return(
        <Container>
            <img src={img} alt="capa da notícia" onClick={()=>navigate('/blog-noticias/1')} />
            <div className="date-info">
                <AiOutlineCalendar className="icon-calendar" />
                <p className="info-date">20 de maio de 2023</p>
            </div>
            <h2 onClick={()=>navigate('/blog-noticias/1')}>Título da postagem que vai ficar acima da notícia</h2>
            <div className="content-preview-text">
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit doloremque magni explicabo deserunt inventore cumque non placeat culpa nihil, ipsam earum corrupti odit eum perspiciatis sit, reprehenderit vel aperiam impedit. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sed laboriosam molestias impedit maxime, tempora non exercitationem facilis culpa enim, fuga minus odit necessitatibus facere, veniam molestiae voluptates itaque amet commodi? Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis illo, ex dignissimos vel quisquam necessitatibus hic quasi perspiciatis reprehenderit blanditiis. Ea non odio ut mollitia molestias numquam aliquid nemo quod.
                </p>
            </div>
            <div className="content-button">
                <button onClick={()=>navigate('/blog-noticias/1')}>Ver mais <FaPlus/></button>
            </div>
            <hr/>
        </Container>
    )
}