import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    overflow-y: auto;

    
`;

export const Content = styled.div`

    .content-screen {
        max-width: 950px;
        margin: 0 auto 4rem;

        @media screen and (max-width:800px){
            padding: 0 1rem;
        }

        .content-button {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 1rem 0 0.5rem 0;

            button {
                background-color: transparent;
                color: var(--background-button-component);
                transition: all.3s;
                border: none;
                border-radius: 6px;
                font-size: 0.8rem;
                display: flex;
                align-items: center;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .title {
            text-align: left;
            margin-top: 2rem;
            margin-bottom: 1rem;
            font-size: 2rem;
            font-weight: 800;
            color: var(--color-title-home);
        }

        img {
            width: 100%;
            height: 450px;
            object-fit: cover;
            border-radius: 6px;

            @media screen and (max-width:800px){
                height: auto;
                object-fit: contain;
            }
        }
        
        .date-info {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0.5rem;
            margin-bottom: 1rem;

            .icon-calendar {
                margin: 0 0.5rem 0 0;
                color: var(--background-button-component);
            }

            .info-date {
                margin: 0;
                color: var(--background-button-component);
            }
        }

        p {
            text-align: justify;
            color: var(--color-font-footer);
        }

        .grid-1 {
            display: grid;
            margin-top: 2rem;
            gap: 1rem;
            grid-template-columns: repeat(2, 1fr);

            .image-grid-1 {
                width: 100%;
                height: 300px;
            }

        }

        .p-3 {
            margin-top: 2rem;
            text-align: justify;
        }

        .grid-images {
            
            img {
                width: 100%;
                height: 500px;
                object-fit: cover;
                margin-bottom: 1rem;

                @media screen and (max-width:800px){
                    height: auto;
                    object-fit: cover;
                }
            }

            .grid {
                display: grid;
                grid-template-columns: repeat(2,1fr);
                gap: 1rem;

                img {
                    width: 100%;
                    height: 300px;
                    object-fit: cover;

                    @media screen and (max-width:800px){
                        height: 100px;
                        object-fit: cover;
                    }
                }
            }

        }
    }
    
    
`;


