import { useCallback, useEffect, useState } from 'react';
import { FaFacebookSquare, FaLinkedin, FaPhoneAlt, FaRegIdBadge, FaUser, FaUserCircle, FaUserEdit } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Header, ScrollProps } from '../../components/Header';
import { IBuildindCorretor, IPropsCorretor } from '../../types';
import { BsInstagram, BsPatchCheckFill } from 'react-icons/bs';
import { TfiIdBadge } from 'react-icons/tfi';
import { BiCreditCardFront } from 'react-icons/bi';
import { PiLinkSimpleBold } from "react-icons/pi";

import {
    Container,
    Wrapper,
    ContentModalAvatar
} from './styles';

import { CardInfo } from '../../components/CardInfo';
import { Footer } from '../../components/Footer';
import { useAuth } from '../../context/auth';
import { LoadSpinner2 } from '../../components/LoadSpinner2';
import { IoMailOutline } from 'react-icons/io5';
import Carousel from 'react-multi-carousel';
import { HiCheck, HiPencil } from 'react-icons/hi';
import { IoMdClose } from 'react-icons/io';
import { GoToTop } from '../../GoToTop';
import { Modal } from 'antd';
import { LoadSpinnerLogin } from '../../components/LoadSpinnerLogin';
import { maskPhone } from '../../utils/mask';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Lottie from 'react-lottie';
import animationData from '../../lotties/loading-white.json';

const MySwal = withReactContent(Swal)

export function InternalProfile() {

    const { user, setAvatarModification, avatarModification, api } = useAuth()

    const fotoPerfil = new FormData();

    const [corretor, setCorretor] = useState<IPropsCorretor>({} as IPropsCorretor)
    const [loading, setLoading] = useState(false)
    const [loading3, setLoading3] = useState(false)

    const loadData = useCallback(async () => {
        setLoading3(true)
        await api.get(`/portal/corretor/${user.id_corretor}`).then(res => {
            setCorretor(res.data)
        }).catch(e => console.log(e)).finally(() => setLoading3(false))
    }, [user.id_corretor, api])

    const navigate = useNavigate();
    const [building, setBuilding] = useState<IBuildindCorretor[]>([]);
    const [buildingDestaque, setBuildingDestaque] = useState<IBuildindCorretor[]>([])
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [page, setPage] = useState(0);
    const [lat, setLat] = useState<number>(0);
    const [long, setLong] = useState<number>(0);

    const loadBuildings = useCallback(() => {
        setLoading(true)
        api.get(`/portal/building/corret-list-building/0?query_search&is_sale&min_sale_price&max_sale_price&is_rent&min_rent_price&max_rent_price&seller=${user.id_corretor}&constructor&no_address=1&id_category&is_highlight&id_corretor=${user.id_corretor}`)
            .then((res) => {
                setBuilding(res.data);
                // console.log(res.data)
            })
            .catch(e => console.log(e.response.data.message))
            .finally(() => setLoading(false));
    }, [user.id_corretor, api]);

    const loadBuildingsDestaque = useCallback(() => {
        // setLoading2(true)
        api.get(`/portal/building/corret-list-building/0?query_search&is_sale&min_sale_price&max_sale_price&is_rent&min_rent_price&max_rent_price&seller&constructor&no_address=1&id_category&is_highlight=1&id_corretor=${user.id_corretor}`).then(res => {
            setBuildingDestaque(res.data)
        }).catch(e => console.log(e)).finally(
            // () => setLoading2(false)
        )
    }, [user, api])

    useEffect(() => {
        loadBuildings();
        loadBuildingsDestaque();
        loadData();
    }, [loadBuildings, loadBuildingsDestaque, loadData]);

    useEffect(() => {
        navigator.geolocation.watchPosition(position => {
            const { latitude, longitude } = position.coords;
            setLat(latitude ? latitude : 0);
            setLong(longitude ? longitude : 0);
        });
    }, [])

    function preparaString(input: string) {
        input = input.toLowerCase().replaceAll(' ', '-').replaceAll('ç', 'c');
        input = input.replace(/[^a-zA-Z\-]/g, '').replaceAll('--', '-')
        return input
    }

    const [scrollTop, setScrollTop] = useState<ScrollProps>({ y: 0, lastY: 0 } as ScrollProps);
    const [show, setShow] = useState(false);

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const scrollTop = event.currentTarget.scrollTop;
        setScrollTop(prevState => { return { y: scrollTop, lastY: prevState.y } })
    }

    const [viewInputNameCorrector, setViewInputNameCorrector] = useState(false);
    const [viewInputEmail, setViewInputEmail] = useState(false);
    const [viewInputContato1, setViewInputContato1] = useState(false);
    const [viewInputInsta, setViewInputInsta] = useState(false);
    const [viewInputFace, setViewInputFace] = useState(false);
    const [editSlug, setEditSlug] = useState(false);
    const [viewInputLinke, setViewInputLinke] = useState(false);
    const [modalAvatar, setModalAvatar] = useState(false);
    const [search, setSearch] = useState('');

    const [contato1, setContato1] = useState('');
    const [inputSlug, setInputSlug] = useState('');
    const [nameVirtualCard, setNameVirtualCard] = useState('');
    const [emailContato, setEmailContato] = useState('');
    const [insta, setInsta] = useState('');
    const [face, setFace] = useState('');
    const [linke, setLinke] = useState('');
    const [avatar, setAvatar] = useState<any>();
    const [successRequisition, setSuccessRequisition] = useState<any>();
    // const [pdfVirtual, setPdfVirtual] = useState('');

    useEffect(() => {
        setContato1(corretor?.telefone_01);
        setEmailContato(corretor?.email_contato);
        setInsta(corretor?.instagram);
        setFace(corretor?.facebook);
        setLinke(corretor?.linkedin);
    }, [corretor])

    const handleEditInfos = useCallback(async () => {
        MySwal.fire({
            title: 'Aguarde',
            text: 'Estamos salvando as informações',
            icon: 'warning',
            didOpen: () => {
                MySwal.showLoading()
            }
        })
        await api.put(`/portal/corretor/${user.id_corretor}`, {
            nome: '',
            creci: '',
            telefone_01: contato1,
            telefone_02: '',
            email_social: '',
            email_contato: emailContato,
            enable_app: '',
            instagram: insta,
            facebook: face,
            linkedin: linke,
            slug: inputSlug
        }).then(res => {
            setContato1('');
            setEmailContato('');
            setInsta('');
            setFace('');
            setLinke('');
            setSuccessRequisition(res.data)
            MySwal.fire({
                icon: 'success',
                title: 'Sucesso!',
                text: 'A edição foi realizada',
                confirmButtonColor: '#0E2244'
            })
        }).catch(e => {
            console.log(e)
            MySwal.fire({
                icon: 'error',
                title: 'Ops!',
                text: `${e.response.data.err}`,
                confirmButtonColor: '#0E2244',
                // footer: `${e.response.data.err}`
            })
        }).finally()
    }, [api, user.id_corretor, contato1, emailContato, insta, face, linke, inputSlug])

    const handleEditAvatar = useCallback(async () => {
        fotoPerfil.append('arquivo', avatar[0]);
        MySwal.fire({
            title: 'Aguarde',
            text: 'Estamos salvando as informações',
            icon: 'warning',
            didOpen: () => {
                MySwal.showLoading()
            }
        })
        await api.post(`/portal/corretor/foto-perfil/${user.id_corretor}`, fotoPerfil).then(res => {
            setModalAvatar(false);
            setAvatarModification(res.data)
            MySwal.fire({
                icon: 'success',
                title: 'Sucesso!',
                text: 'A edição foi realizada',
                confirmButtonColor: '#0E2244'
            })
        }).catch(e => {
            console.log(e);
            MySwal.fire({
                icon: 'error',
                title: 'Ops!',
                text: 'Não foi possível editar esta informação',
                confirmButtonColor: '#0E2244',
                footer: `${e.response.data.err}`
            })
        })
    }, [user, avatar, fotoPerfil, setAvatarModification, api])

    useEffect(() => {
        loadData()
    }, [loadData, avatarModification, successRequisition])

    const [loadingPdfCreator, setLoadingPdfCreator] = useState(false);

    const cartaoVirtual = useCallback(async () => {
        try {
            const response = await api.get(`/utils/get-contact-pdf/${user.id_corretor}`, {
                responseType: 'arraybuffer',
            })

            return new Blob([response.data], { type: 'application/pdf' });
        } catch (error) {
            console.error('Erro ao buscar o documento PDF:', error);
            return null;
        }
    }, [api, user.id_corretor])

    const openPDFcartao = async () => {
        setLoadingPdfCreator(true)
        const pdfBlob = await cartaoVirtual()

        if (pdfBlob) {
            const pdfUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfUrl, '_blank')
        }
        setLoadingPdfCreator(false)
    };

    const defaultOptions = {
        loop: true,
        animationData: animationData,
    }

    const handleEditNameVirtualCard = useCallback(async () => {
        MySwal.fire({
            title: 'Aguarde',
            text: 'Estamos salvando as informações',
            icon: 'warning',
            didOpen: () => {
                MySwal.showLoading()
            }
        })
        await api.post(`/sin/corretor/nome-cartao/${user.id_corretor}`, {
            nome: nameVirtualCard
        }).then(res => {
            setNameVirtualCard('')
            setViewInputNameCorrector(false)
            loadData()
            MySwal.fire({
                icon: 'success',
                title: 'Sucesso!',
                text: 'A edição foi realizada',
                confirmButtonColor: '#0E2244'
            })
        }).catch(e => {
            console.log(e)
            MySwal.fire({
                icon: 'error',
                title: 'Ops!',
                text: 'Não foi possível editar esta informação',
                confirmButtonColor: '#0E2244',
                footer: `${e.response.data.err}`
            })
        }).finally()
    }, [nameVirtualCard, api, user, loadData])

    const handleEditSlug = () => {
        MySwal.fire({
            title: 'Atenção!',
            text: 'Você está preste a alterar a URL do seu link de vendas. Seus links personalizados enviados anteriormente deixarão de funcionar. Tem certeza que deseja alterar?',
            icon: 'warning',
            confirmButtonText: 'SIM, ESTOU CIENTE',
        }).then((result) => {
            if (result.isConfirmed) {
                setEditSlug(true);
            }
        })
    }

    return (
        <Container onScroll={handleScroll}>
            <Header show={show} setShow={setShow} scroll={scrollTop} search={search} setSearch={setSearch} />
            {
                (loading3) &&
                <LoadSpinner2 />
            }
            {
                (!loading3) &&
                <>
                    <Wrapper>
                        <div className="box-left">
                            <div className="photo">
                                {
                                    corretor?.foto_perfil ?
                                        <>
                                            <img onClick={() => setModalAvatar(true)} src={corretor?.foto_perfil} className='avatar' alt='Avatar do usuário' />
                                            <p onClick={() => setModalAvatar(true)} className='edition'>Editar <HiPencil /></p>
                                        </>
                                        :
                                        <>
                                            <FaUserCircle onClick={() => setModalAvatar(true)} className='avatar' />
                                            <p onClick={() => setModalAvatar(true)} className='edition'>Editar <HiPencil /></p>
                                        </>
                                }
                                <h1>{corretor?.nome}</h1>
                                <p>Especialista Nogueira <BsPatchCheckFill className='check' /> </p>
                            </div>
                            <div className="validate-profile">
                                <ul>
                                    <li>
                                        <TfiIdBadge className='icons-corretor' />
                                        <p className='infos-person'>
                                            CRECI: {corretor?.creci}
                                        </p>
                                    </li>
                                    <li>
                                        <FaUserEdit className='icons-corretor' />
                                        {viewInputNameCorrector ?
                                            <><input type="text" placeholder='Nome no cartão virtual' value={nameVirtualCard} onChange={(e) => setNameVirtualCard(e.target.value)} /><button className='button-cancel' onClick={() => setViewInputNameCorrector(false)}><IoMdClose /></button><button className='button-accept' onClick={() => { setViewInputNameCorrector(false); handleEditNameVirtualCard() }}><HiCheck /></button></>
                                            :
                                            <p className='infos-person'>{corretor?.nome_cartao_virtual ? `Nome cartão virtual: ${corretor?.nome_cartao_virtual}` : 'Não informado'}</p>
                                        }
                                        <HiPencil className='editions-infos' onClick={() => setViewInputNameCorrector(true)} />
                                    </li>
                                    <li>
                                        <IoMailOutline className='icons-corretor' />
                                        {viewInputEmail ?
                                            <><input type="text" placeholder='seuemail@email.com' value={emailContato} onChange={(e) => setEmailContato(e.target.value)} /><button className='button-cancel' onClick={() => setViewInputEmail(false)}><IoMdClose /></button><button className='button-accept' onClick={() => { setViewInputEmail(false); handleEditInfos() }}><HiCheck /></button></>
                                            :
                                            <p className='infos-person'>{corretor?.email_contato ? corretor?.email_contato : 'Não informado'}</p>
                                        }
                                        <HiPencil className='editions-infos' onClick={() => setViewInputEmail(true)} />
                                    </li>
                                    <li>
                                        <FaPhoneAlt className='icons-corretor' />
                                        {viewInputContato1 ?
                                            <><input type="text" maxLength={11} value={maskPhone(contato1 ? contato1 : '')} onChange={(e) => setContato1(e.target.value)} /><button className='button-cancel' onClick={() => setViewInputContato1(false)}><IoMdClose /></button><button className='button-accept' onClick={() => { setViewInputContato1(false); handleEditInfos() }}><HiCheck /></button></>
                                            :
                                            <p className='infos-person'>{corretor?.telefone_01 ? maskPhone(corretor?.telefone_01 ? corretor?.telefone_01 : '') : 'Não informado'}</p>
                                        }
                                        <HiPencil className='editions-infos' onClick={() => setViewInputContato1(true)} />
                                    </li>
                                    <li>
                                        <BsInstagram className='icons-corretor' />
                                        {viewInputInsta ?
                                            <><input value={insta} onChange={(e) => setInsta(e.target.value)} type="text" /><button className='button-cancel' onClick={() => setViewInputInsta(false)}><IoMdClose /></button><button className='button-accept' onClick={() => { setViewInputInsta(false); handleEditInfos() }}><HiCheck /></button></>
                                            :
                                            <p className='infos-person'>{corretor?.instagram ? corretor?.instagram : 'Não informado'}</p>
                                        }
                                        <HiPencil className='editions-infos' onClick={() => setViewInputInsta(true)} />
                                    </li>
                                    <li>
                                        <FaFacebookSquare className='icons-corretor' />
                                        {viewInputFace ?
                                            <><input type="text" value={face} onChange={(e) => setFace(e.target.value)} /><button className='button-cancel' onClick={() => setViewInputFace(false)}><IoMdClose /></button><button className='button-accept' onClick={() => { setViewInputFace(false); handleEditInfos() }}><HiCheck /></button></>
                                            :
                                            <p className='infos-person'>{corretor?.facebook ? corretor?.facebook : 'Não informado'}</p>
                                        }
                                        <HiPencil className='editions-infos' onClick={() => setViewInputFace(true)} />
                                    </li>
                                    <li>
                                        <FaLinkedin className='icons-corretor' />
                                        {viewInputLinke ?
                                            <><input type="text" value={linke} onChange={(e) => setLinke(e.target.value)} /><button className='button-cancel' onClick={() => setViewInputLinke(false)}><IoMdClose /></button><button className='button-accept' onClick={() => { setViewInputLinke(false); handleEditInfos() }}><HiCheck /></button></>
                                            :
                                            <p className='infos-person'>{corretor?.linkedin ? corretor?.linkedin : 'Não informado'}</p>
                                        }
                                        <HiPencil className='editions-infos' onClick={() => setViewInputLinke(true)} />
                                    </li>
                                    <li title='Link do seu portal'>
                                        <PiLinkSimpleBold className='icons-corretor' />
                                        {editSlug ?
                                            <>
                                                <input type="text" placeholder='URL do seu portal' value={inputSlug} onChange={(e) => setInputSlug(preparaString(e.target.value))} />
                                                <button className='button-cancel' onClick={() => setEditSlug(false)}><IoMdClose /></button>
                                                <button className='button-accept' onClick={() => { setEditSlug(false); handleEditInfos() }}><HiCheck /></button></>
                                            :
                                            <p className='infos-person'>{corretor?.slug ? corretor?.slug : 'Não informado'}</p>
                                        }
                                        <HiPencil className='editions-infos' onClick={() => handleEditSlug()} />
                                    </li>
                                </ul>
                                <div className='content-buttons-corretor'>
                                    <button onClick={() => navigate(`/perfil/${user.id_corretor}`)}>
                                        Acessar perfil
                                        <FaUser className='icon-badge1' />
                                    </button>
                                    <button onClick={openPDFcartao}>
                                        {
                                            loadingPdfCreator ?
                                                <>
                                                    Carregando
                                                    <Lottie options={defaultOptions} height={25} width={25} style={{ margin: '0', padding: '0' }} />
                                                </>
                                                :
                                                <>
                                                    Gerar cartão virtual
                                                    <FaRegIdBadge className='icon-badge2' />
                                                </>
                                        }
                                    </button>
                                    <button className='button-kanban' onClick={() => navigate('/kanban')}>
                                        Propostas Digitais
                                        <BiCreditCardFront className='icon-badge3' />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="box-right">
                            <div className="info-member">
                                <div className="info-member-name">
                                    <h1>{corretor?.nome}</h1>
                                </div>
                            </div>

                            <div className="announcement">
                                <h3>Seus destaques</h3>
                                <div className="cards">
                                    <Carousel
                                        additionalTransfrom={0}
                                        arrows
                                        autoPlay={true}
                                        autoPlaySpeed={3000}
                                        centerMode={false}
                                        className="content-carrosel"
                                        containerClass="container-with-dots"
                                        dotListClass=""
                                        draggable
                                        focusOnSelect={false}
                                        infinite
                                        itemClass="item"
                                        keyBoardControl
                                        minimumTouchDrag={80}
                                        pauseOnHover
                                        renderArrowsWhenDisabled={true}
                                        renderButtonGroupOutside={true}
                                        renderDotsOutside={false}
                                        responsive={{
                                            desktop: {
                                                breakpoint: {
                                                    max: 3000,
                                                    min: 1024
                                                },
                                                items: 2,
                                                partialVisibilityGutter: 40,

                                            },
                                            mobile: {
                                                breakpoint: {
                                                    max: 464,
                                                    min: 0
                                                },
                                                items: 1,
                                                partialVisibilityGutter: 30
                                            },
                                            tablet: {
                                                breakpoint: {
                                                    max: 1024,
                                                    min: 464
                                                },
                                                items: 2,
                                                partialVisibilityGutter: 30
                                            }
                                        }}
                                        rewind={false}
                                        rewindWithAnimation={false}
                                        rtl={false}
                                        shouldResetAutoplay
                                        showDots={false}
                                        sliderClass="slide"
                                        slidesToSlide={1}
                                        swipeable
                                        partialVisbile={false}
                                        partialVisible={false}
                                    >
                                        {buildingDestaque && buildingDestaque.map((item, indx) => {
                                            return (
                                                <CardInfo
                                                    key={indx}
                                                    id={item.id}
                                                    descricao={item.descricao}
                                                    latitude={item.latitude}
                                                    longitude={item.longitude}
                                                    valor_aluguel={item.valor_aluguel}
                                                    valor_venda={item.valor_venda}
                                                    is_venda={item.is_venda}
                                                    is_aluguel={item.is_aluguel}
                                                    periodo_aluguel={item.periodo_aluguel}
                                                    arquivos={item.arquivos}
                                                    lat={lat}
                                                    long={long}
                                                    onClick={() => navigate(`/perfil/${user.id_corretor}/imovel/${item.id}`)}
                                                    idBuilding={item.id}
                                                    is_fixado={item.is_fixado}
                                                    is_destaque={item.is_destaque}
                                                    is_lancamento={item.is_lancamento}
                                                    bairro={item.bairro}
                                                    cidade={item.cidade}
                                                    marginRight='10px'
                                                />
                                            )
                                        })
                                        }
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </Wrapper>

                    <div className='conten-cards-imoveis'>
                        <h3>Seus imóveis adicionados</h3>
                        <div className="imoveis">
                            {building && building.map((item, index) => {
                                return (
                                    <CardInfo
                                        key={index}
                                        id={item.id}
                                        descricao={item.descricao}
                                        latitude={item.latitude}
                                        longitude={item.longitude}
                                        valor_aluguel={item.valor_aluguel}
                                        valor_venda={item.valor_venda}
                                        is_venda={item.is_venda}
                                        is_aluguel={item.is_aluguel}
                                        periodo_aluguel={item.periodo_aluguel}
                                        arquivos={item.arquivos}
                                        lat={lat}
                                        long={long}
                                        onClick={() => navigate(`/perfil/${user.id_corretor}/imovel/${item.id}`)}
                                        idBuilding={item.id}
                                        is_fixado={item.is_fixado}
                                        is_destaque={item.is_destaque}
                                        is_lancamento={item.is_lancamento}
                                        bairro={item.bairro}
                                        cidade={item.cidade}
                                        marginRight='10px'
                                    />
                                )
                            })
                            }
                        </div>
                    </div>


                </>
            }

            <Modal
                centered
                width={500}
                open={modalAvatar}
                onOk={() => setModalAvatar(false)}
                onCancel={() => setModalAvatar(false)}
                footer={null}
                zIndex={14687464295707467926762967645641561362516532084261456326424642466}
            >
                <ContentModalAvatar>
                    {
                        loading &&
                        <LoadSpinnerLogin />
                    }
                    {
                        !loading &&
                        <>
                            <p className='title-logout'>Editar sua foto de perfil</p>
                            <div className='content-button'>
                                <input type="file" onChange={(e) => setAvatar(e.target.files)} />
                                <button onClick={handleEditAvatar} >Salvar</button>
                            </div>
                        </>
                    }

                </ContentModalAvatar>
            </Modal>

            <GoToTop />
            <Footer />
        </Container >
    );
}