import { useCallback, useEffect, useState } from "react"
import { ContentWrapper } from "./styles";
import { useAuth } from "../../../context/auth";
import { LoadSpinnerLogin } from "../../../components/LoadSpinnerLogin";


interface IframeComponentProps {
    id_proposta: number;
    src: string;
    title: string;
    width?: string;
    height?: number;
}


interface IFormProps {
    id_proposta: number
    // setModalOpen: (v: boolean) => void
}


interface IProposition {
    uuid: string;
    cliente_nome: string,
    imovel_descricao: string,
    imovel_construtora: string,
    imovel_bloco?: string,
}


export function ModalContent(props: IFormProps) {

    const [proposition, setProposition] = useState({} as IProposition);
    const [loading, setLoading] = useState(true);
    const { api, user } = useAuth();


    const loadContract = useCallback(async () => {
        setLoading(true);
        await api.get(`/sin/proposta/${user.id_corretor}/${props.id_proposta}`).then(res => {
            console.log(res.data);
            setProposition({
                uuid: `${res.data.proposta[0].uuid}`,
                cliente_nome: `${res.data.cliente[0].nome}`,
                imovel_construtora: `${res.data.proposta[0].construtora}`,
                imovel_descricao: `${res.data.proposta[0].descricao_imovel}`,
                imovel_bloco: `${res.data.proposta[0].bloco}`,
            });
            setLoading(false);
        }).catch(e => console.log(e))
    }, [user.id_corretor, api, props])


    useEffect(() => {
        loadContract();
    }, [props, loadContract])

    return (
        <ContentWrapper>

            {loading ?
            <div style={{minHeight:300, display:'flex', alignItems:'center'}}>
                <LoadSpinnerLogin />
            </div>
                 :
                <>
                    <div style={{ marginTop: 8, marginBottom: 20 }}>
                        <h1 style={{ marginBottom: 0, fontSize: 30 }}>{proposition.cliente_nome} </h1>
                        <h2 style={{ marginTop: -12, color: `rgb(90, 90, 90)` }} >{proposition.imovel_descricao}{proposition.imovel_bloco ? `, bloco ${proposition.imovel_bloco}` : ''}</h2>
                    </div>

                    <IframeComponent
                        src={`${process.env.REACT_APP_SIN_URL}/flystart/app/api/contrato/${proposition.uuid}`}
                        title="Contrato"
                        width={'100%'}
                        id_proposta={props.id_proposta}
                    />
                </>
            }

        </ContentWrapper>
    )
}


const IframeComponent: React.FC<IframeComponentProps> = ({
    src,
    title,
    width = 600,
    height = 600,
}) => {
    return (
        <div>
            <iframe
                title={title}
                width={width}
                height={height}
                src={src}
                style={{border:`none`, background:'#e7e7e7'}}
            ></iframe>
        </div>
    );
};
