import styled from "styled-components";

export const Container = styled.div`
    width: 100%;

    img {
        width: 100%;
        height: 450px;
        object-fit: cover;
        border-radius: 6px;
        cursor: pointer;

        @media screen and (max-width:800px){
            height: auto;
            object-fit: contain;
        }
    }

    .date-info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0.5rem;

        .icon-calendar {
            margin: 0;
        }

        .info-date {
            margin: 0;
        }
    }

    h2 {
        margin: 1rem 0;
        cursor: pointer;
        color: var(--background-button-component);
    }

    .content-preview-text {
        height: 100px;
        display: -webkit-box;
        -webkit-line-clamp: 4; /** número de linhas que você quer exibir */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        
        
        p {
            color:var(--color-font-footer);
            line-height: 1.5rem;
            text-align: justify;
        }
    }

    .content-button {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;

        button {
            background-color: var(--background-button-component);
            border: 1px solid var(--color-font-footer);
            display: flex;
            color: var(--bg-color);
            gap: 0.5rem;
            border-radius: 6px;
            padding: 0.5rem;
            font-size: 0.9rem;
            cursor: pointer;
            transition: .3s;

            &:hover {
                filter: brightness(0.7);
            }
        }
    }

    hr {
        margin: 1rem 0;
    }

`;