import styled from 'styled-components';

export const Container = styled.div`

  .conten-cards-imoveis {
    max-width: 1200px;
    padding: 0;
    margin: 0 auto 3rem auto;
    

    h3 {
      font-weight: bold;
      margin-bottom: 1rem;
    }

    .container-with-dots {
    }

    .imoveis {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 0.5rem;
      width: 100%;

      @media screen and (max-width: 980px) {
    
          grid-template-columns: repeat(3, 1fr);
        
      }
      @media screen and (max-width: 760px) {
        
          grid-template-columns: repeat(2, 1fr);
        
      }
      @media screen and (max-width: 500px) {
        
          grid-template-columns: 1fr;
        
      }
    }
  }
  

  

  .conten-cards-imoveis-responsive {
    display: none;
    max-width: 1200px;
    padding: 0 4%;
    margin: 0 auto 3rem auto;
    

    h3 {
      font-weight: bold;
      margin-bottom: 1rem;
    }

    .container-with-dots-responsive {
    }

    .imoveis-responsive {
      display: flex;
      /* grid-template-columns: repeat(4, 1fr); */
      gap: 0.5rem;
      width: 100%;
    }
  }

  /* @media screen and (max-width:800px){
    .conten-cards-imoveis {
      display: none;
    }
    .conten-cards-imoveis-responsive {
      display: flex;

      .content-carrosel-responsive {
        display:flex;
      }

      .container-with-dots-responsive {
        display: flex;
      }
    } 
  } */
`;

export const Wrapper = styled.section`
  display: flex;
  gap: 2rem;
  max-width: 1200px;
  padding: 2rem 0 3rem;
  margin: 2rem auto 0 auto;

  .box-left {
    width: 30%;
    max-height:750px;
    padding: 1rem;
    border-radius: 1rem;
    border: 1px solid rgba(0,0,0, .1);

    .button-register-property {
      width: 100%;
      border: none;
      padding: 1rem;
      border-radius: 5px;
      background-color: var(--background-button-component);
      color: var(--bg-color);
      cursor: pointer;
    }

    .photo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      border-bottom: 1px solid rgba(0,0,0, .1);

      h1 {
        display: none;

        @media screen and (max-width:800px){
          display: flex;
        }
      }

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 128px;
        height: 128px;
        margin-bottom: 1rem;
        border-radius: 50%;
        background-color: rgba(0,0,0, .1);
      }

      .check {
        font-size: 0.9rem;
        color: var(--primary-green);
      }

      .avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 120px;
        object-fit: cover;
        border-radius: 50%;
        margin-bottom: 1rem;
        color: var(--background-button-component);
        transition: 0.5s;
        position: relative;

        
      }

      .edition {
        color: var(--bg-color);
        position: absolute;
        font-weight: 400;
        margin-top: 45px;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        display: none;
        transition: 0.5s;
      }

      p {
        font-size: 1rem;
      }

      &:hover {
        .avatar {
          filter: brightness(0.8);
          cursor: pointer;
        }

          .edition {
            display: flex;
            cursor: pointer;

            @media screen and (max-width:800px){
              margin-top: -.1rem;
            }
          }
        }
    }

   
      
    

    .validate-profile {
      margin-top: 1rem;
      white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

      h4 {
        font-size: 1.3rem;
        font-weight: 600;
        margin: 1rem 0;
      }

      ul {
        list-style: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        .icons-corretor {
          margin: 0 0.3rem 0 0;
          font-size: 1.1rem;
          color: var(--background-button-component);
        }

        .editions-infos {
          margin-left: 0.3rem;
          font-size: 1rem;
          cursor: pointer;
        }

        li {
          display: flex;
          align-items: center;
          font-size: 0.8rem;
          line-height: 28px;
          margin: 0 0 0.5rem 5px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          /* width: 96%; */
          /* border: 1px solid red; */
          
          .infos-person{
            display: flex;
            align-items: center;
            font-size: 0.8rem;
            line-height: 28px;
            margin: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-word;
            word-wrap: break-word;
            width: 85%;
          }

          input {
            border: 1px solid var(--color-placeholder-input);
            border-radius: 6px;
            padding-left:0.5rem;
            font-size: 0.8rem;

            @media screen and (max-width:800px){
              width: 100%;
            }
          }

          .button-cancel {
            border:none;
            display: flex;
            align-items: center;
            color: var(--bg-color);
            background: var(--primary-red);
            margin: 0.25rem 0.25rem 0.25rem 0.5rem;
            padding: 0.5rem;
            border-radius: 6px;
            cursor: pointer;
          }

          .button-accept {
            border:none;
            display: flex;
            align-items: center;
            color: var(--bg-color);
            background: var(--primary-green);
            margin: 0.25rem;
            padding: 0.5rem;
            border-radius: 6px;
            cursor: pointer;
          }
        }
      }

      .content-buttons-corretor {

        button {
          padding: 0.5rem 1rem;
          background-color: var(--background-button-component);
          color: white;
          border: none;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          transition: .3s;
          width: 100%;
          height: 3rem;
          font-weight: 500;

          &:hover {
            filter: brightness(1.8);
          }

          & + button {
            background-color: var(--primary-green);
            margin-top: 0.5rem;
          }

          .icon-badge1 {
            font-size:1.2rem;
          }

          .icon-badge2 {
            font-size: 1.5rem;
          }

          @media screen and (max-width:800px){
            font-size: .9rem;

            .icon-badge1 {
              font-size:1rem;
            }

            .icon-badge2 {
              font-size:1rem;
            }
          }
        }

        .button-kanban {
          padding: 0.5rem 1rem;
          background-color: orange;
          color: white;
          border: none;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          transition: .3s;
          width: 100%;
          height: 3rem;
          font-weight: 500;

          &:hover {
            filter: brightness(1.8);
          }
          
          .icon-badge3 {
            font-size: 1.5rem;
          }
        }
      }
    }

  }
  
  .box-right {
    width: 70%;

    .info-member {
      display: flex;
      justify-content: space-between;
    }

    .info-member .info-member-name {
      display: flex;
      gap: 1rem;
      margin-bottom: 0.5rem;

      h1 {
        font-size: 2rem;
        margin-bottom: 5px;
      }

      
      
    }

    .info-member .info-member-photo-perfil {
      display: none;
      justify-content: center;
      align-items: center;
      width: 88px;
      height: 88px;
      border-radius: 50%;
      background-color: rgba(0,0,0, .1);
    }

    p {
      color: #777;
    }

    .about {
      padding-bottom: 1rem;
      margin-top: 3rem;
      margin-bottom: 1rem;

      h3 {
        font-size: 1.5rem;
      }

      p {
        color: #444;
      }
    }
  }

  .box-right .announcement {
    width: 100%;
    border-top: 1px solid rgba(0,0,0, .1);

    @media screen and (max-width:800px){
      border:none;
    }

    h3 {
      color: #444;
      font-weight: 600;
      margin: 2rem 0 1rem;
      
    }

    .cards {
      /* padding-bottom: 2rem; */
      
      .content-carrosel {
        display: flex;
      }
      .container-with-dots {
        display: flex;
      }
      .item {
        /* margin: 0 1px */
      }
      .imoveis {
        display: flex;
      }
      .slide {
        /* border: 1px solid red; */
      }
    }

  }

  @media screen and (max-width: 800px) {
    /* max-width: 760px; */
    flex-direction:column;

    .box-left {
      width: 100%;
    }

    .box-right {
      width: 100%;

      .info-member {
        display: none;
      }

      .info-member .info-member-photo-perfil {
        display: flex;
      }
    }
  }
  
`;

export const ContentModalAvatar = styled.div`
  padding: 1rem;

  .title-logout {
    font-size: 0.9rem;
  }

  .content-button {
    display: flex;
    justify-content: center;
    gap: 0.5rem;

    input {
      border: 1px solid var(--color-placeholder-input);
      border-radius: 6px;
      padding-left:0.5rem;
      font-size: 0.8rem;
      width: 500px;
    }

    button {
      padding: 0.5rem 0.5rem;
      border: none;
      background-color: var(--primary-green);
      color: var(--bg-color);
      font-weight: 600;
      font-family: 'Poppins', sans-serif;
      border-radius: 6px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        filter:brightness(0.8);
      }
    }

    @media screen and (max-width:800px){
      flex-direction: column;

      input {
        width: 100%;;
      }

      button {
        width: 100%;
      }
    }
  }
`;