import styled from 'styled-components';

interface ContainerProps {
  marginRight?: string;
  marginLeft?: string;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 1;
  margin-right: ${({marginRight})=> marginRight ? marginRight : '0'};
  left: ${({marginLeft})=> marginLeft ? marginLeft : '0'};

  .icon-add {
    position:absolute;
    right: 10px;
    top: 10px;
    z-index: 2000000000;
    font-size: 1.2rem;
    color: var(--background-button-component);
    background-color: var(--bg-color);
    padding: 1px;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      filter: brightness(0.7);
    }
  }

  .icon-add-2 {
    position:absolute;
    right: 10px;
    top: 10px;
    z-index: 2000000000;
    font-size: 1.2rem;
    color: var(--bg-color);
    background-color: var(--background-button-component);
    padding: 1px;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      filter: brightness(0.7);
    }
  }

  .add-destaque {
    position:absolute;
    right: 35px;
    top: 10px;
    z-index: 2000000000;
    font-size: 1.125rem;
    color: var(--background-button-component);
    background-color: var(--bg-color);
    padding: 1.1px;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      filter: brightness(0.7);
    }
  }

  .add-destaque-golden {
    position:absolute;
    right: 35px;
    top: 10px;
    z-index: 2000000000;
    font-size: 1.125rem;
    color: var(--primary-gold);
    background-color: var(--bg-color);
    padding: 1.1px;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      filter: brightness(0.7);
    }
  }

  .destaque {
    position: absolute;
    top: 10px;
    left: 10px;
    color: var(--bg-color);
    text-shadow: rgba(0,0,0,0.6) 0.1em 0.1em 0.2em;
    font-weight: 600;
    font-size: 0.6rem;
    padding: 0.2rem;
    border-radius: 6px;
    z-index: 454878;
    background: var(--secondary-blue);
  }
  
  .img {
    /* width: 100%;
    height: 260px; */
    border-radius: 1rem;
    background-color: rgba(0,0,0, .1);
    cursor: pointer;
    overflow: hidden;

    .img-build {
      width: 100%;
      height: 260px;
      border-radius: 1rem;
      object-fit: cover;
    }
    
    .img-placeholder {
      display: flex;
      width: 100%;
      height: 260px;
      justify-content: center;
      align-items: center;
      height: 100%;

      svg {
        color: rgba(0,0,0, .1);
        font-size: 5rem;
      }
    }
  }
  
  .wrapper {
    display: flex;
    flex-direction: column;
    line-height: 10px;
    width: 100%;

    .address-and-distance {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      -webkit-line-clamp: 1; // número de linhas que você quer exibir 
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 18px;

      .address-build {
        font-size: .85rem;
        font-weight: 600;
        margin: 0 ;
        max-width: 80%;
        /* overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; */
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        -webkit-box-orient: vertical; 
      }

      .info {
        /* color: #777; */
        font-size: 0.7rem;
        max-width: 20%;
        margin: 0;
      }

    }

    .label-imovel {
      font-size: 0.8rem;
      color: #777;
      font-weight: 500;
    }

    .description {
      /* display: -webkit-box;
      -webkit-line-clamp: 1; // número de linhas que você quer exibir 
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; */
      /* height: 20px; */
      /* max-width: 280px; */
      /* margin-bottom: 1rem; */
      /* display: -webkit-box;
      -webkit-line-clamp: 1; // número de linhas que você quer exibir 
      -webkit-box-orient: vertical; */
      /* overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; */
      display: -webkit-box;
      -webkit-line-clamp: 1;
      overflow: hidden;
      -webkit-box-orient: vertical; 
      font-size: .85rem;
      font-weight: 400;
      line-height: 18px;
      margin: 0 0 0.5rem;
      width: 100%;
      color: var(--color-font-footer);
      
    }
    
    .period {
      color: var(--background-button-component);
      span {
        font-size: 0.8rem;
      }
    }

    .price {
      color: var(--background-button-component);
    }
  }
`;