import styled from "styled-components";

export const Container = styled.div`
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    /* padding: 0 4%; */
    margin: 0 auto;
    border-top: 2px solid var(--background-footer);
    border-bottom: 2px solid var(--background-footer);
    background-color: white;
    
    .filters-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: auto;
    
        .filters-icons {
            width: 100%;
            height: auto;

            .carroussel-construcors {
                z-index: 2;
            }

            .container-with-dots {
                padding-left: 4rem;
                display: flex;
                justify-content: space-between;
                z-index: 2;
            }

            .slide {
            }

            .content-slide {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row;
                margin-left: 15px;
                margin-right: 15px;
                width: 100%;

                .card {
                    width: 180px;

                    @media screen and (max-width: 1080px) {
                       width: 80px;
                    }
                }

                .icon-convenience {
                    width: 40px;

                    @media screen and (max-width: 1080px) {
                       width: 30px;
                    }
                }
            }
            
            
            .filter-single {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
                padding-top: 12px;
                padding-bottom: 0px;
                cursor: pointer;
                transition: 0.5s;

                &:hover::after, &.active::after{
                content: '';
                position: absolute;
                transition: 0.5s;
                left: 0;
                bottom: -3px;
                width: 100%;
                height: 3px;
                background-color: #444;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                }
                &:hover::after {
                background-color: #888;
                transition: 0.5s;
                }
                &.active::after {
                background-color: #444;
                transition: 0.5s;
                }

                span {
                    font-size: 1.4rem;
                }

                .name-filter {
                    font-size: .9rem;

                    @media screen and (max-width: 1080px) {
                        font-size: .55rem;
                    }
                }

                @media screen and (max-width: 1080px) {
                    padding-top: .6rem;
                    padding-bottom: 0;
                }
            }
        }
    } 

  @media screen and (max-width: 800px) {
      padding: 0 4%;
  }
`;