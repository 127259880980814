import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 250px;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    
    &:hover {
        img {
            filter: brightness(0.8);
            transform: scale(1.1);
        }

        .content-text .title {
            font-size: 1rem;
        }
    }
    
    img {
        transition: all 0.3s ease-in-out;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
        
    }
        
    .content-text {
        margin-top: -90px;
        padding-left: 1rem;
       
        .title {
            color: #fff;
            font-size: 0.8rem;
            font-weight: bold;
            text-shadow: 1px 1px 2px black;
            transition: 0.3s;
            position: absolute;
            text-align: left;
        }
        .subtitle {
            transition: 0.3s;
            color: #fff;
            text-shadow: 1px 1px 2px black;
            position: absolute;
            bottom: 10px;
            height: 40px;
            display: -webkit-box;
            -webkit-line-clamp: 2; /** número de linhas que você quer exibir */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align:left;
        }
    }
`;