import { createContext, useCallback, useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
// import { toast } from 'react-toastify';
import { IPropsCategoriaImovel, IPropsCorretor, IPropsType, IPropsUserLogged } from '../types';
// import { toast } from 'react-toastify';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios, { AxiosInstance } from 'axios';

const MySwal = withReactContent(Swal)

interface User {
    aud: string;
    azp: string;
    email: string;
    email_verified: boolean;
    exp: number;
    family_name: string;
    given_name: string;
    iat: number;
    iss: string;
    jti: string;
    name: string;
    nbf: number;
    picture: string;
    sub: string;
}

interface AuthState {
    user: IPropsUserLogged;
    token: string;
    refreshToken: string;
}

interface SignInCredentials {
    email: string;
    objUser: User;
}

interface PropsSignInWithEmailAndPassword {
    email: string;
    password: string;
}

interface AuthContextProps {
    user: IPropsUserLogged;
    signIn(credentials: SignInCredentials): Promise<void>;
    signInWithEmailAndPassword(credentials: PropsSignInWithEmailAndPassword): Promise<void>;
    signOut(): void;
    loading: boolean;
    person: IPropsCorretor;
    avatarModification: any;
    setAvatarModification: (val: any) => void;
    categorys: IPropsCategoriaImovel[];
    typeBuild: IPropsType[];
    api: AxiosInstance;
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

const AuthProvider = ({ children }: any) => {

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false);
    const [person, setPerson] = useState<IPropsCorretor>({} as IPropsCorretor)
    const [categorys, setCategorys] = useState<IPropsCategoriaImovel[]>([])
    const [typeBuild, setTypeBuild] = useState<IPropsType[]>([]);
    const [avatarModification, setAvatarModification] = useState<any>();


    const api = axios.create({
        baseURL: `${process.env.REACT_APP_API_URL}`
    });

    
    const [data, setData] = useState<AuthState>(() => {
        const token = localStorage.getItem('@HubNogueira:token');
        const user = localStorage.getItem('@HubNogueira:user');
        const refreshToken = localStorage.getItem('@HubNogueira:refreshToken')

        if (user && token && refreshToken) {
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            return { user: JSON.parse(user), token, refreshToken }
        }

        return {} as AuthState
    })


    //função de login
    const signIn = useCallback(async ({ email, objUser }: SignInCredentials) => {
        setLoading(true)
        await api.post('/social-login', {
            email,
        }).then(res => {
            const user = {
                email,
                family_name: objUser.family_name,
                given_name: objUser.given_name,
                name: res?.data?.user?.name,
                picture: objUser.picture,
                slug: res?.data?.user?.slug,
                enable_app: res?.data?.user.enable_app,
                id_corretor: res?.data?.user.id_corret_corretor,
                id_pessoa: res?.data?.user.id_pessoa
            }
            api.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`;
            localStorage.setItem('@HubNogueira:token', res.data.token);
            localStorage.setItem('@HubNogueira:refreshToken', res.data.refreshToken)
            localStorage.setItem('@HubNogueira:user', JSON.stringify(user));
            // console.log('logado')

            setData({ user, token: res.data.token, refreshToken: res.data.refreshToken })
            navigate('/perfil-interno')
        }).catch(e => {
            console.log(e)
            MySwal.fire({
                icon: 'error',
                title: 'Desculpe',
                text: `${e.response.data}`,
                confirmButtonColor: '#0E2244',
                footer: `Entre em contato com o suporte Nogueira`
            })
        }).finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //função login com email e senha
    const signInWithEmailAndPassword = useCallback(async ({ email, password }: PropsSignInWithEmailAndPassword) => {
        setLoading(true);
        await api.post('/login', { email, password }).then(res => {
            const user = {
                email,
                family_name: res?.data?.user?.apelido,
                given_name: res?.data?.user?.apelido,
                name: res?.data?.user?.apelido?.split('')[0],
                picture: '',
                slug: res?.data?.user?.portal_slug,
                enable_app: res?.data?.user?.enable_app,
                id_corretor: res?.data?.user?.id_corret_corretor,
                id_pessoa: res?.data?.user?.id_pessoa
            }
            api.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`;
            localStorage.setItem('@HubNogueira:token', res.data.token);
            localStorage.setItem('@HubNogueira:refreshToken', res.data.refreshToken)
            localStorage.setItem('@HubNogueira:user', JSON.stringify(user));

            setData({ user, token: res.data.token, refreshToken: res.data.refreshToken })
            navigate('/perfil-interno')
        }).catch(e => {
            MySwal.fire({
                icon: 'error',
                title: 'Desculpe',
                text: `${e.response.data}`,
                confirmButtonColor: '#0E2244',
                footer: `Entre em contato com o suporte Nogueira`
            })
        }).finally(() => setLoading(false))
    }, [api, navigate])

    //função de carregar informações do corretor logado
    useEffect(() => {
        if (data?.user !== undefined) {
            api.get(`/portal/corretor/${data?.user?.id_corretor}`).then(res => {
                setPerson(res.data)
            }).catch(e => console.log(e))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    //função para carregar categorias
    useEffect(() => {
        api.get('/portal/category').then(res => {
            setCategorys(res.data)
        }).catch(e => console.log(e)).finally()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //função para carregar os tipos de imóveis
    useEffect(() => {
        api.get('/portal/tipo').then(res => {
            setTypeBuild(res.data)
        }).catch(e => console.log(e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //função para deslogar
    const signOut = useCallback(() => {
        localStorage.removeItem('@HubNogueira:user');
        localStorage.removeItem('@HubNogueira:token');
        localStorage.removeItem('@HubNogueira:refreshToken')
        navigate('/')
        setData({} as AuthState)
    }, [navigate])


    api.interceptors.request.use(
        async (config) => {
            // Adicionar o token de autenticação à solicitação se disponível
            const token = data.token;
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    api.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            const originalRequest = error.config;
            
            if (error?.response?.status === 401 && !originalRequest._retry && data.refreshToken) {
                originalRequest._retry = true;
                try {
                    const response = await api.post(`/refresh-token`, {
                        refresh_token: data.refreshToken,
                    });

                    if (!response.data.success) {
                        return signOut()
                    }

                    const { token, refreshToken, } = response.data;

                    const user = {
                        email: response?.data?.user?.email_social,
                        family_name: response?.data?.user?.apelido,
                        given_name: response?.data?.user?.apelido,
                        name: response?.data?.user?.apelido?.split('')[0],
                        picture: '',
                        slug: response?.data?.user?.portal_slug,
                        enable_app: response?.data?.user?.enable_app,
                        id_corretor: response?.data?.user?.id_corret_corretor,
                        id_pessoa: response?.data?.user?.id_pessoa
                    }

                    localStorage.setItem("@HubNogueira:user", JSON.stringify(user));
                    localStorage.setItem("@HubNogueira:token", token)
                    localStorage.setItem("@HubNogueira:refreshToken", refreshToken)



                    setData({ user, token, refreshToken });
                    originalRequest.headers.Authorization = `Bearer ${token}`;
                    return axios(originalRequest);

                } catch (refreshError) {
                    signOut();
                    throw refreshError;
                }

            } else if ((error?.response?.status === 401 && originalRequest._retry) || !data.refreshToken) {
                signOut();
            }

            return Promise.reject(error);
        }
    );

    return (
        <AuthContext.Provider value={{
            user: data.user,
            signIn,
            signInWithEmailAndPassword,
            signOut,
            loading,
            person,
            avatarModification,
            setAvatarModification,
            categorys,
            typeBuild,
            api
        }}>
            {children}
        </AuthContext.Provider>
    )
}

function useAuth(): AuthContextProps {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context
}

export { AuthProvider, useAuth };