import { useCallback, useMemo, useState } from 'react';
import { IDistance, IPropCardInfo } from '../../types';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  Container,
} from './styles';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import { useAuth } from '../../context/auth';
import { BsStar, BsStarFill } from 'react-icons/bs';
import { toast } from 'react-toastify';

export function CardInfo({ id, descricao, latitude, longitude, periodo_aluguel, valor_venda, valor_aluguel, is_venda, is_aluguel, arquivos, lat, long, onClick, idBuilding, is_fixado, is_destaque, is_lancamento, bairro, cidade, marginRight, marginLeft }: IPropCardInfo) {

  const { user, api } = useAuth()

  const [destaque, setDestaque] = useState(false)
  const [viewDestaque, setViewDestaque] = useState(false)
  const [addSuccess, setAddSuccess] = useState(false);


  const distance = ({ lat1, lon1, lat2, lon2 }: IDistance) => {
    const earthRadius = 6371; // raio da Terra em km
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
      + Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2))
      * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c; // distância em km
    return distance;
  }

  const toRadians = (degrees: number) => {
    return degrees * Math.PI / 180;
  }

  const getDistance = useMemo(() => {
    if (lat && long && latitude && longitude !== null) {
      return distance({ lat1: lat, lon1: long, lat2: Number(latitude), lon2: Number(longitude) });
    } else {
      return -1
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, long, latitude, longitude])

  const handleAddToProfile = useCallback(async (idBuilding: number) => {
    await api.post('/portal/seller/add-to-profile', {
      id_corretor: user.id_corretor,
      id_imovel: idBuilding
    }).then(res => {
      setAddSuccess(true)
      setViewDestaque(true)
      return toast.success('Imóvel adicionado com sucesso ao seu perfil', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    }).catch(e => {
      console.log(e)
      return toast.error('Não foi possível adicionar o imóvel ao seu perfil', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    })
  }, [user, api])

  const handleRemoveToProfile = useCallback(async (idBuilding: number) => {
    await api.post('/portal/seller/remove-from-profile', {
      id_corretor: user.id_corretor,
      id_imovel: idBuilding
    }).then(res => {
      setAddSuccess(false)
      setViewDestaque(false)
      return toast.success('Imóvel retirado com sucesso do seu perfil', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    }).catch(e => {
      console.log(e)
      return toast.error('Não foi possível retirar o imóvel do seu perfil', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    })
  }, [user,api])

  const handleAddDestaque = useCallback(async (idBuilding: number) => {
    await api.post('/portal/seller/highlight', {
      id_corretor: user.id_corretor,
      id_imovel: idBuilding
    }).then(res => {
      setDestaque(true)
      return toast.success('Imóvel adicionado com sucesso aos seus destaques', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    }).catch(e => {
      console.log(e)
      toast.error('Não foi possível adicionar o imóvel aos destaques', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
      toast.error('Adicione o imóvel ao seu perfil primeiro', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    })
  }, [user,api])

  const handleRemoveDestaque = useCallback(async (idBuilding: number) => {
    await api.post('/portal/seller/remove-highlight', {
      id_corretor: user.id_corretor,
      id_imovel: idBuilding
    }).then(res => {
      setDestaque(false)
      return toast.success('Imóvel retirado com sucesso dos seus destaques', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    }).catch(e => {
      console.log(e)
      return toast.error('Não foi possível retirar o imóvel dos destaques', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    })
  }, [user,api])

  return (
    <Container marginRight={marginRight} marginLeft={marginLeft} >
      {
        user &&
        <>
          {
            (is_fixado === 1 || addSuccess) ?
              <FiMinusCircle className='icon-add' onClick={() => handleRemoveToProfile(idBuilding)} />
              :
              ((is_fixado === null || !addSuccess) ?
                <FiPlusCircle className={'icon-add'} onClick={() => handleAddToProfile(idBuilding)} />
                :
                <></>
              )
          }
        </>
      }
      {
        (user && (is_fixado === 1 || viewDestaque)) &&
        <>
          {
            (is_destaque === 1 || destaque ) ?
              <BsStarFill className='add-destaque-golden' onClick={() => handleRemoveDestaque(idBuilding)} />
              :
              ((is_destaque == null || !destaque ) ?
                <BsStar className='add-destaque' onClick={() => handleAddDestaque(idBuilding)} />
                :
                <></>
              )
          }
        </>
      }
      {
        is_lancamento === 1 &&
        <div className='destaque'>
          LANÇAMENTO
        </div>
      }
      <div className="img">
        {(arquivos && arquivos[0]?.url !== undefined && arquivos[0].url !== 'https://www.nogueiracorretores.com.br/')
          ?
          <Carousel
            autoPlay={true}
            interval={5000}
            showThumbs={false}
            infiniteLoop={true}
            showStatus={false}
            onClickItem={onClick}
            // showArrows={true}
          >
            {arquivos.map(file => (
              <img key={file.id} className='img-build' src={file.url} alt="Imóvel" />
            ))}
          </Carousel>
          : <img className='img-placeholder' onClick={onClick} src={'https://portal-nogueira.s3.us-east-2.amazonaws.com/26848b2b91cbd520d366-placeholder-image-nogueira.png'} alt="placeholder-nogueira" />
        }
      </div>
      <div className="wrapper">
        <div className='address-and-distance'>
          <p className='address-build'>{`${bairro}, ${cidade}`}</p>
          <p className="info">
          {(Number(getDistance) && getDistance !== -1)
            ? new Intl.NumberFormat('pt-BR', {
              notation: 'compact',
              style: 'unit',
              unit: 'kilometer',
              unitDisplay: "narrow",
            }).format(getDistance)
            : ''
          }
        </p>
        </div>
        
          <p className='description'>{descricao}</p>
        
        
        {
          is_aluguel === 1 &&
          <div>
            <p className='label-imovel'>Aluguel:</p>
            <div>
              <p className="period">{valor_aluguel ? Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(valor_aluguel) : "0,00"}<span>/{periodo_aluguel === 1 ? 'dia' : (periodo_aluguel === 2 ? 'semana' : (periodo_aluguel === 3 ? 'mês' : 'ano'))}</span></p>
            </div>
          </div>
        }
        {
          is_venda === 1 &&
          <div>
            <p className='label-imovel'>Venda:</p>
            <p className="price">{valor_venda ? Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL'
            }).format(valor_venda) : "0,00"} </p>
          </div>
        }
      </div>
    </Container>
  );
}