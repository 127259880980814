import { useCallback, useEffect, useState } from "react";
import { Header, ScrollProps } from "../../components/Header";
import {
    Container,
    TitlePage,
    ContentHeader,
    ComplementHeader,
    ContentHeaderMobile
} from "./styles";
import { IBuildigCategory, IBuildindCorretor } from "../../types";
import { CardInfo } from "../../components/CardInfo";
import { useNavigate, useParams } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";
import { LoadSpinner2 } from "../../components/LoadSpinner2";
import { useAuth } from "../../context/auth";
import HeaderCategories from "../../components/HeaderCategories";
import { SadAnimation } from "../../components/SadAnimation";
import { GoToTop } from "../../GoToTop";
import { useSearch } from "../../context/search";
import { useSearchAdvanced } from "../../context/searchAdvanced";
import { Footer } from "../../components/Footer";

export function SearchResultCategory() {

    const {
        user,
        api
    } = useAuth();

    const {
        setCategorySelected,
        setOperationSelected,
        setSearch,
        // loading,
        // building
    } = useSearch()

    const {
        buildingAdvanced,
        setAdvancedOperationAluguel,
        setAdvancedOperationLancamento,
        setAdvancedOperationVenda,
        setAdvancedSearchOperation,
        setCardsCategoriesSelect,
        setCity,
        setDistrict,
        setState,
        setComodoBathrooms,
        setComodoBedRooms,
        setComodoSuites,
        setComodoVagas,
        setRangerPriceRentFinal,
        setRangerPriceRentInitial,
        setRangerPriceSaleFinal,
        setRangerPriceSaleInitial,
        setTypeBuildsSelected,
        loadingAdvanced,
    } = useSearchAdvanced()

    const backToTheHome = useCallback(() => {
        //search comum
        setSearch('');
        setOperationSelected([]);
        setCategorySelected('');

        //search advanced
        setAdvancedOperationAluguel(true)
        setAdvancedOperationLancamento(true)
        setAdvancedOperationVenda(true)
        setAdvancedSearchOperation([])
        setCardsCategoriesSelect([])
        setCity('')
        setDistrict('')
        setState('')
        setComodoBathrooms(0)
        setComodoBedRooms(0)
        setComodoSuites(0)
        setComodoVagas(0)
        setRangerPriceRentFinal(0)
        setRangerPriceRentInitial(0)
        setRangerPriceSaleFinal(0)
        setRangerPriceSaleInitial(0)
        setTypeBuildsSelected([])

        navigate('/');
    }, [])

    const { id } = useParams();

    const [scrollTop, setScrollTop] = useState<ScrollProps>({ y: 0, lastY: 0 } as ScrollProps);
    const [show, setShow] = useState(false);
    // const [search, setSearch] = useState('');

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const scrollTop = event.currentTarget.scrollTop;
        setScrollTop(prevState => { return { y: scrollTop, lastY: prevState.y } })
    }

    const [loading, setLoading] = useState(false);
    const [building, setBuilding] = useState<IBuildindCorretor[]>([])
    const [categorys, setCategorys] = useState<IBuildigCategory[]>([])
    const [searchResult, setSeachResult] = useState(id);

    const loadCategorys = useCallback(async () => {
        await api.get('/portal/category').then(res => {
            setCategorys(res.data)
        }).catch(e => console.log(e)).finally()
    }, [])

    useEffect(() => { loadCategorys() }, [])

    const loadBuildings = useCallback(async () => {
        if (user) {
            setLoading(true)
            await api.get(`/portal/building/corret-list-building/0?query_search&is_sale&min_sale_price&max_sale_price&is_rent&min_rent_price&max_rent_price&seller&constructor&no_address=1&id_category=${searchResult}&is_highlight&id_corretor=${user.id_corretor}`)
                .then((res) => {
                    setBuilding(res.data)
                    console.log(res.data)
                })
                .catch(e => console.log(e.response.data.message))
                .finally(() => setLoading(false));
        } else {
            setLoading(true)
            await api.get(`/portal/building/list-building/0?query_search&is_sale&min_sale_price&max_sale_price&is_rent&min_rent_price&max_rent_price&seller&constructor&no_address&id_category=${searchResult}&is_highlight`)
                .then((res) => {
                    setBuilding(res.data)
                    console.log(res.data)
                })
                .catch(e => console.log(e.response.data.message))
                .finally(() => setLoading(false));
        }
    }, [searchResult, user]);

    useEffect(() => { loadBuildings() }, [user, searchResult])
    // console.log(search)
    const navigate = useNavigate()

    return (
        <Container onScroll={handleScroll}>
            <Header show={show} setShow={setShow} scroll={scrollTop}  />
            <HeaderCategories setSearch={setSeachResult} handleSearch={loadBuildings} />
            <ContentHeader>
                <button onClick={backToTheHome}>
                    <IoChevronBackOutline className="icon" />
                    Volta para início
                </button>
                <TitlePage>Resultados da pesquisa: Categoria {categorys && categorys.map(cat => cat.id === Number(searchResult) && cat.titulo)}</TitlePage>
                <ComplementHeader />
            </ContentHeader>
            <ContentHeaderMobile>
                <div className="content-button-back-home">
                    <button onClick={backToTheHome}>
                        <IoChevronBackOutline className="icon" />
                        Volta para início
                    </button>
                </div>
                <div className="content-title">
                    <h2>Resultados da pesquisa: Categoria {categorys && categorys.map(cat => cat.id === Number(searchResult) && cat.titulo)}</h2>
                </div>
            </ContentHeaderMobile>
            {
                loading &&
                <LoadSpinner2 />
            }
            {
                !loading &&
                <>
                    {
                        building?.length > 0 ?
                            <>
                                <div className="content-search">
                                    {
                                        building && building.map(b => (
                                            <CardInfo
                                                id={b.id}
                                                key={b.id}
                                                descricao={b.descricao}
                                                valor_aluguel={b.is_aluguel}
                                                is_aluguel={b.is_aluguel}
                                                is_venda={b.is_venda}
                                                latitude={b.latitude}
                                                longitude={b.longitude}
                                                periodo_aluguel={b.periodo_aluguel}
                                                valor_venda={b.valor_venda}
                                                arquivos={b.arquivos}
                                                onClick={() => navigate(user ? `/perfil/${user.id_corretor}/imovel/${b.id}` : `/imovel/${b.id}`)}
                                                idBuilding={b.id}
                                                is_fixado={b.is_fixado}
                                                is_destaque={b.is_destaque}
                                                is_lancamento={b.is_lancamento}
                                                bairro={b.bairro}
                                                cidade={b.cidade}
                                            />
                                        ))
                                    }
                                </div>
                            </>
                            :
                            <div className="no-result">
                                <SadAnimation />
                                <p >Nenhum item encontrado em sua busca.</p>
                            </div>
                    }
                </>
            }
            <GoToTop />
            <Footer />
        </Container>
    )
}