import React, { ReactElement, ReactNode } from 'react';

import { AuthProvider } from './auth';
import { SearchProvider } from './search';
import { SearchAdvancedProvider } from './searchAdvanced';

const AppProvider = ({ children }: any) => {
    return (
        <AuthProvider>
            <SearchAdvancedProvider>
                <SearchProvider>
                    {children}
                </SearchProvider>
            </SearchAdvancedProvider>
        </AuthProvider>
    )
};

export default AppProvider;