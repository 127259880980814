import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2% 4%;
  background-color: #000;
  z-index: 65465742098765968776852426;
  /* overflow-y: auto; */

  

  & {
    scrollbar-width: thin;
    scrollbar-color: rgba(0,0,0, .3) transparent;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 3px; /* A altura só é vista quando a rolagem é horizontal */
  }

  &::-webkit-scrollbar-track {
    background: rgba(0,0,0, .1);
    padding: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0, .3);
  }
  
  .all-photos {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: .5rem;
    margin-top: 2rem;
    padding-bottom: 4rem;
    border-radius: 1rem;
  }
  
  .all-photos .div-img {
    border-radius: 1rem;
    background-color: rgba(0,0,0, .1);
    max-width: 1350px;
    
    .content-photo {
      display: flex;
      width: 100%;
      /* height: 690px; */
      position: relative;
      
      .image {
        width: 100%;
        max-height: 760px;
        border-radius: 1rem;
        object-fit: cover;
      }

        .icon {
          max-width: 35px;
          border-radius: 50%;
          background-color: rgba(0,0,0, .080);
          transition: all .2s ease;
          cursor: pointer;
          position: absolute;
          top:3%;
          left:1%;
          z-index: 65465742098765968776852426546479687654;
          border: 1px solid #888888;
          /* box-shadow: 10px 10px 8px 10px #888888; */
          
          &:hover {
            background-color: rgba(0,0,0, .098);
          }
        }
      
    }

    
  }

  .mbottom {
    margin-top: 4rem;
  }

  /* @media screen and (max-width: 1100px) {
    .header {
      margin-top: 2rem;
    }
    .all-photos {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media screen and (max-width: 760px) {
    .all-photos {
      grid-template-columns: 1fr;
    }
  } */

`;