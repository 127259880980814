import { useState } from "react";
import { Container, Content } from "./style";
import { Header, ScrollProps } from "../../components/Header";
import img from '../../assets/jpg/h10.jpg';
import img2 from '../../assets/jpg/h11.jpg';
import img3 from '../../assets/jpg/h2.jpg';
import img4 from '../../assets/jpg/h6.jpg';
import img5 from '../../assets/jpg/h8.jpg';
import { AiOutlineCalendar } from "react-icons/ai";
import { GoToTop } from "../../GoToTop";
import { Footer } from "../../components/Footer";
import { HiChevronLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { ButtonWhats } from "../../components/ButtonWhats";

export function DetailsBlog() {

    const navigate = useNavigate()

    const [scrollTop, setScrollTop] = useState<ScrollProps>({ y: 0, lastY: 0 } as ScrollProps);
    const [show, setShow] = useState(false);
    const [search, setSearch] = useState('')

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const scrollTop = event.currentTarget.scrollTop;
        setScrollTop(prevState => { return { y: scrollTop, lastY: prevState.y } })
    }

    return (
        <Container onScroll={handleScroll}>
            <Header scroll={scrollTop} show={show} setShow={setShow} search={search} setSearch={setSearch} />
            <Content>
                <div className="content-screen">
                    <div className="content-button">
                        <button onClick={() => navigate('/blog-noticias')}><HiChevronLeft size={16} /> Voltar</button>
                    </div>
                    <img src={img} alt="imagem de abertura do blog" />
                    <p className='title'>TÍTULO DA NOTÍCIA DO BLOG</p>
                    <div className="date-info">
                        <AiOutlineCalendar className="icon-calendar" />
                        <p className="info-date">20 de maio de 2023</p>
                    </div>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iure, provident numquam laudantium, impedit quasi consequuntur eligendi minima vel accusamus reprehenderit ducimus! Esse fugiat labore harum praesentium suscipit at est temporibus! Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro molestias neque fugiat, cum earum dicta id quasi fugit dolorum, distinctio ullam accusantium et ab rem? Eos culpa officia accusamus placeat? Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore facere aut sapiente earum doloremque libero officiis explicabo illum unde odio ipsa dolores architecto in, eligendi qui repellat repudiandae, quam labore! Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores voluptas officia nemo, sequi repellat delectus ex repellendus error ea quae consectetur, quia eaque quis alias natus qui, modi earum nam? Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse laudantium ab maxime ullam, vero repellendus veritatis quia perferendis nesciunt vitae saepe, est, dolor magni error rerum a iste dolorem nostrum!</p>
                    <div className="grid-1">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Est doloremque totam asperiores soluta officia fugiat delectus! Fugit laborum maxime tenetur molestias impedit unde et tempore sequi cumque vitae! Odio, sunt? Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores iste dolore cumque, sequi esse officia quam repudiandae mollitia repellendus voluptate rem tempore similique temporibus asperiores in, incidunt repellat illo. Nostrum. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Minus voluptatum cumque quae quaerat aliquam adipisci quia assumenda? Impedit nemo provident, amet commodi blanditiis repellendus animi magni exercitationem facere excepturi voluptas? Minus voluptatum cumque quae quaerat aliquam adipisci quia assumenda? Impedit nemo provident, amet commodi blanditiis repellendus animi magni exercitationem facere excepturi voluptas?</p>
                        <img className="image-grid-1" src={img2} alt='segunda imagem do blog' />
                    </div>
                    <p className="p-3">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iure, provident numquam laudantium, impedit quasi consequuntur eligendi minima vel accusamus reprehenderit ducimus! Esse fugiat labore harum praesentium suscipit at est temporibus! Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro molestias neque fugiat, cum earum dicta id quasi fugit dolorum, distinctio ullam accusantium et ab rem? Eos culpa officia accusamus placeat? Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore facere aut sapiente earum doloremque libero officiis explicabo illum unde odio ipsa dolores architecto in, eligendi qui repellat repudiandae, quam labore! Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores voluptas officia nemo, sequi repellat delectus ex repellendus error ea quae consectetur, quia eaque quis alias natus qui, modi earum nam? Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse laudantium ab maxime ullam, vero repellendus veritatis quia perferendis nesciunt vitae saepe, est, dolor magni error rerum a iste dolorem nostrum!</p>
                    <div className="grid-images">
                        <img src={img3} alt="imagem" />
                        <div className="grid">
                            <img src={img4} alt="imagem" />
                            <img src={img5} alt='imagem' />
                        </div>
                    </div>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, suscipit. Non praesentium voluptatibus officiis labore ipsum et vel, aliquam exercitationem eaque, quibusdam eum. Nesciunt at impedit quia aperiam, enim tenetur? Lorem, ipsum dolor sit amet consectetur adipisicing elit. Distinctio, ex! Vitae minima sapiente temporibus maiores soluta ex repellat ad harum commodi fugiat accusamus, rerum numquam nostrum, consequatur non tempora voluptatibus? Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores voluptatibus, quod molestias perspiciatis assumenda dolores reprehenderit ab voluptas quas, corporis tempore laborum, consequatur facilis! Perspiciatis sint repudiandae iusto saepe architecto. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolor amet, voluptates a necessitatibus eaque rerum nobis ipsam distinctio quaerat officia omnis, cupiditate neque esse laborum inventore quam est provident illo. Lorem ipsum dolor sit amet consectetur adipisicing elit. A, natus quam repudiandae deleniti omnis error maiores et neque. Adipisci, hic atque rerum saepe itaque nam repellat delectus sed voluptatum ex.</p>
                </div>
                <GoToTop />
                
                <Footer />
            </Content>
        </Container>
    )
}