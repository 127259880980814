import { CgCloseO } from 'react-icons/cg';
import { IArquivo } from '../../types';
import { Container } from "./styles";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';

interface IProps {
  isOpen?: boolean;
  file?: string;
  onClose: () => void;
  files?: IArquivo[];
  index?: number;
}

export function ShowPhotoLargue({ onClose, files, index }: IProps) {
  // console.log(files)

  return (
    <Container >
      {/* <div className="header"> */}

      {/* </div> */}

      <div className="all-photos">

        <div className="div-img">
          <Splide options={{
            perPage: 1,
            perMove: 1,
            // autoWidth: true,
            // type: 'fade',
            gap: '2rem',
            width: '100%',
            start: index
          }}>
            {files?.map((images, indx) => (
              <SplideSlide key={indx}>
                <div className='content-photo'>
                  <img className='image' onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "https://portal-nogueira.s3.us-east-2.amazonaws.com/26848b2b91cbd520d366-placeholder-image-nogueira.png";
                  }} src={images.url} alt="Imagens Nogueira" />
                  <CgCloseO
                    size={32}
                    onClick={onClose}
                    color='#fff'
                    className='icon'
                  />
                </div>
              </SplideSlide>
            ))
            }
          </Splide>
        </div>

      </div>
    </Container >
  );
}