import { createContext, useCallback, useState, useContext, useEffect } from 'react'
// import { useNavigate } from 'react-router-dom';
import { IBuildindCorretor } from '../types';
// import { toast } from 'react-toastify';
import { useAuth } from './auth';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

interface SearchContextProps {
    categorySelected: string;
    setCategorySelected: (val: string) => void;
    operationSelected: string[];
    setOperationSelected: (val: string[]) => void;
    search: string;
    setSearch: (val: string) => void;
    handleSearchBuildings: () => void;
    building: IBuildindCorretor[];
    buildingTotal: IBuildindCorretor[];
    loading: boolean;
    loadingScroll: boolean;
    scrollPages: () => void;
}

const SearchContext = createContext<SearchContextProps>({} as SearchContextProps);

const SearchProvider = ({ children }: any) => {

    // const navigate = useNavigate()
    const { user, api } = useAuth();

    const [loading, setLoading] = useState(false);
    const [loadingScroll, setLoadingScroll] = useState(false);
    const [operationSelected, setOperationSelected] = useState<string[]>([]);
    const [categorySelected, setCategorySelected] = useState<string>('');
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0)
    const [fazerReq, setFazerReq] = useState(true)

    const [building, setBuilding] = useState<IBuildindCorretor[]>([])
    const [buildingTotal, setBuildingTotal] = useState<IBuildindCorretor[]>([])

    const handleSearchBuildings = useCallback(async () => {
        setLoading(true)
        setBuilding([])
        setBuildingTotal([])

        await api.get(`/portal/building/${user ? 'corret-' : ''}list-building/0?query_search=${search}&is_sale=${operationSelected.includes('1') ? '1' : ''}&min_sale_price&max_sale_price&is_rent=${operationSelected.includes('2') ? '1' : ''}&min_rent_price&max_rent_price&seller&constructor&no_address=1&id_category=${categorySelected}&is_highlight&id_corretor=${user ? user.id_corretor : ''}&is_launch=${operationSelected.includes('0') ? '1' : ''}`).then(res => {
            setBuilding(res.data)
            setBuildingTotal(res.data);
            setPage(1)
        }).catch(e => console.log(e)).finally(() => setLoading(false))

        setLoading(false)
    }, [search, operationSelected, categorySelected]);

    const loadBuildingsScroll = useCallback(async () => {

        setLoadingScroll(true)
        await api.get(`/portal/building/${user ? 'corret-' : ''}list-building/${page}?query_search=${search}&is_sale=${operationSelected.includes('1') ? '1' : ''}&min_sale_price&max_sale_price&is_rent=${operationSelected.includes('2') ? '1' : ''}&min_rent_price&max_rent_price&seller&constructor&no_address=1&id_category=${categorySelected}&is_highlight&id_corretor=${user ? user.id_corretor : ''}&is_launch=${operationSelected.includes('0') ? '1' : ''}`)
            .then((res) => {
                if (res.data.lenght < 12) {
                    setFazerReq(false)
                }
                setBuilding(res.data)
                // console.log(res.data)
                setBuildingTotal(prevBuildingTotal => [...prevBuildingTotal, ...res.data]);
            })
            .catch(e => console.log(e.response.data.message))
            .finally(() => setLoadingScroll(false));

    }, [page, user, operationSelected, categorySelected]);

    const scrollPages = useCallback(() => {
        if (fazerReq) {
            if (page > 0 && building.length > 0) {
                loadBuildingsScroll();
                // console.log(page);
            }
            setPage((pageInsideState) => pageInsideState + 1)
        }

        // console.log(page)
    }, [page, fazerReq])

    return (
        <SearchContext.Provider value={{
            categorySelected,
            setCategorySelected,
            operationSelected,
            setOperationSelected,
            search,
            setSearch,
            building,
            handleSearchBuildings,
            loading,
            loadingScroll,
            buildingTotal,
            scrollPages
        }}>
            {children}
        </SearchContext.Provider>
    )
}

function useSearch(): SearchContextProps {

    const context = useContext(SearchContext);

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context

}

export { SearchProvider, useSearch };