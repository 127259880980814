import styled from "styled-components";

interface ButtonSelectionProps {
    active: boolean;
  }

export const Container = styled.div`
    .content {
        min-height: 575px;
        width: var(--wrapper-width);
        margin: 0 auto 2rem auto; 
        padding-top: 2rem;

        .content-pass {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;

            .traco {
                width: 100%;
                height: 2px;
                background-color: var(--background-footer);
            }
        }

        .content-buttons-back-advance {
            display: flex;
            gap:1rem;
            align-items: center;
            justify-content: flex-end;
            margin-top: 1.5rem;
            width: 100%;

            .back {
                font-size: 0.8rem;
                color:  #fff;
                border-radius: 5px;
                border: 1px solid var(--background-footer);
                height: 40px;
                padding: 0.5rem;
                width: 100px;
                font-weight: 500;
                cursor: pointer;
                transition: 0.3s;
                background-color: var(--color-placeholder-input);
            }

            .advance {
                font-size: 0.8rem;
                color:  #fff;
                border-radius: 5px;
                border: 1px solid var(--background-footer);
                height: 40px;
                padding: 0.5rem;
                width: 100px;
                font-weight: 500;
                cursor: pointer;
                transition: 0.3s;
                background-color: var(--background-button-component);
            }
        }

        .content-map {
            margin-top: 2rem;
        }

        .content-map-placeholder {
            margin-top: 2rem;
            position: relative;

            img {
                width: 100%;
                height: 300px;
                border-radius: 10px;
                opacity: 0.15;
                object-fit: cover;
            }

            p {
                position: absolute;
                top: 50%;
                right: 25%;
                left: 25%;
                
            }
        }

        .content-maps-conference {
            display: grid;
            grid-template-columns: repeat(2,1fr);
            gap: 1rem;
        }

        .subtitle-maps {
            text-align: center;
            margin-top: 2rem;
        }

        .content-endereco {
            display: grid;
            grid-template-columns: repeat(2,1fr);
            gap: 1rem;

            .left-endereco {
                .number-complement {
                    display: grid;
                    grid-template-columns: repeat(2,1fr);
                    gap:1rem;
                }
                .label-input {
                    margin-top:1rem;
                    input {
                        border-radius: 5px;
                        border: 1px solid var(--background-footer);
                        height: 40px;
                        padding: 0.5rem;
                        width: 100%;
                        background-color: var(--background-input);
                    }
                    select {
                        border-radius: 5px;
                        border: 1px solid var(--background-footer);
                        height: 40px;
                        padding: 0.5rem;
                        width: 100%;
                        background-color: var(--background-input);
                        padding-right: 1rem;
                    }

                    p {
                        color: var(--color-font-footer);
                        font-size: 0.8rem;
                        margin-bottom: 0;
                    }
                }
            }

            .right-endereco {
                .label-input {
                    margin-top:1rem;
                    input {
                        border-radius: 5px;
                        border: 1px solid var(--background-footer);
                        height: 40px;
                        padding: 0.5rem;
                        width: 100%;
                        background-color: var(--background-input);
                    }
                    select {
                        border-radius: 5px;
                        border: 1px solid var(--background-footer);
                        height: 40px;
                        padding: 0.5rem;
                        width: 100%;
                        background-color: var(--background-input);
                        padding-right: 1rem;
                    }

                    p {
                        color: var(--color-font-footer);
                        font-size: 0.8rem;
                        margin-bottom: 0;
                    }
                }
            }
        }

        .text-area {

            margin-top: 1rem;
            margin-bottom: 1rem;

            p {
                color: var(--color-font-footer);
                font-size: 0.8rem;
                margin-bottom: 0;
            }

            textarea {
                border-radius: 5px;
                border: 1px solid var(--background-footer);
                height: 120px;
                padding: 0.5rem;
                width: 100%;
                background-color: var(--background-input);
            }
        }

        .label-input-desc {
            margin-top:1rem;
            input {
                border-radius: 5px;
                border: 1px solid var(--background-footer);
                height: 40px;
                padding: 0.5rem;
                width: 100%;
                background-color: var(--background-input);
            }

            p {
                color: var(--color-font-footer);
                font-size: 0.8rem;
                margin-bottom: 0;
            }
        }

        .content-inputs {
            display: grid;
            gap: 1rem;
            grid-template-columns: repeat(2,1fr);

            .left {

                .div-buttons {
                    margin-top: 1.9rem;
                    display: grid;
                    grid-template-columns: repeat(2,1fr);
                    gap: 1rem;
                }

                .label-input {
                    margin-top:1rem;
                    input {
                        border-radius: 5px;
                        border: 1px solid var(--background-footer);
                        height: 40px;
                        padding: 0.5rem;
                        width: 100%;
                        background-color: var(--background-input);
                    }
                    select {
                        border-radius: 5px;
                        border: 1px solid var(--background-footer);
                        height: 40px;
                        padding: 0.5rem;
                        width: 100%;
                        background-color: var(--background-input);
                        padding-right: 1rem;
                    }

                    p {
                        color: var(--color-font-footer);
                        font-size: 0.8rem;
                        margin-bottom: 0;
                    }
                }
                
            }

            .right {

                .values {
                    display: grid;
                    grid-template-columns: repeat(2,1fr);
                    gap: 1rem;
                }

                .label-input {
                    margin-top:1rem;
                    input {
                        border-radius: 5px;
                        border: 1px solid var(--background-footer);
                        height: 40px;
                        padding: 0.5rem;
                        width: 100%;
                        background-color: var(--background-input);
                    }

                    input:disabled {
                        opacity: 0.5;
                    }

                    select {
                        border-radius: 5px;
                        border: 1px solid var(--background-footer);
                        height: 40px;
                        padding: 0.5rem;
                        width: 100%;
                        background-color: var(--background-input);
                        padding-right: 1rem;
                    }

                    p {
                        color: var(--color-font-footer);
                        font-size: 0.8rem;
                        margin-bottom: 0;
                    }
                }

            }

        }

        .label-textarea {
            margin-top:1rem;
            textarea {
                border-radius: 5px;
                border: 1px solid var(--background-footer);
                height: 150px;
                padding: 0.5rem;
                width: 100%;
                background-color: var(--background-input);
            }
            

            p {
                color: var(--color-font-footer);
                font-size: 0.8rem;
                margin-bottom: 0;
            }
        }

        .label-file {
            margin-top:1rem;
            input {
                border-radius: 5px;
                border: 1px solid var(--background-footer);
                height: 40px;
                padding: 0.5rem;
                width: 100%;
                font-size: 0.8rem;
                background-color: var(--background-input);
            }

            p {
                color: var(--color-font-footer);
                font-size: 0.8rem;
                margin-bottom: 0;
            }
        }

        .button-handle {
            margin-top: 1.5rem;
            display: flex;
            justify-content: flex-end;
            button {
                font-size: 0.8rem;
                color:  #fff;
                border-radius: 5px;
                border: 1px solid var(--background-footer);
                height: 40px;
                padding: 0.5rem;
                width: 200px;
                font-weight: 500;
                cursor: pointer;
                transition: 0.3s;
                background-color: var(--background-button-component);
            }
        }
    }
`;

export const ButtonComponent = styled.button<ButtonSelectionProps>`
    font-size: 0.8rem;
    color: ${(props) => props.active ? '#fff' : 'var(--color-font-footer)'} ;
    border-radius: 5px;
    border: 1px solid var(--background-footer);
    height: 40px;
    padding: 0.5rem;
    width: 100%;
    cursor: pointer;
    transition: 0.3s;
    background-color: ${(props) => props.active ? 'var(--background-button-component)' : 'var(--background-input)'} ;
`;