import styled from "styled-components";


export const CardStyled = styled.div`
    height: 141.3vw;
    width: 100vw;
    position: relative;
    
    img.bg-img {
        width: 100%;
        /* height: 100%; */
        margin-top: -20%;
    }

    div.content {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        
        color: 04162a;
        display: grid;
        grid-template-rows: 40% 21% 31% 8%;

        div.header {
            text-align: center;
            img.img-header {
                text-align: center;
                width: 80vw;
                margin-top: 4%;
            }
        }

        div.user_info {
            width: 100%;
            display: flex;
            justify-content: space-between;

            div.tab {
                background-color: #04162a;
                height: 50%;
                width: 15%;
            }

            div.left-tab {
                border-radius: 0px 40% 40% 0px;
            }

            div.right-tab {
                border-radius: 40% 0% 0% 40%;
            }

            div.user_data {
                line-height: 6.5vw;
                text-align: center;
                div.name {
                    span.frist_name {
                        font-size: 8vw;
                            font-weight: 800;
                    }
                    span.last_name {
                        font-size: 8vw;
                    }
                }
                div.function {
                    font-size: 4vw;
                }
                div.creci {
                    font-weight: 700;
                    font-size: 4vw;
                }
            }
        }

        div.social_grid {
            width: 60vw;
            margin: auto;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            gap: 2vw;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 10vw;
                border-radius: 50%;
                aspect-ratio: 1;
                height: 16vw;
            }

            a.dark {
                background-color: #04162a;
                color: white;
            }
            a.light {
                background-color: #6c6c6c;
                color: white;
            }
        }

        div.footer {
            background-color: #04162a !important;
            border-radius: 20px 20px 0% 0%;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 4vw;
        }
    }

`;


