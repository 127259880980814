import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    overflow-y: auto;

    
`;

export const Content = styled.div`

    .title {
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 2.5rem;
        font-size: 2rem;
        font-weight: 800;
        color: var(--color-title-home);
    }

    .content-list {
        max-width: 950px;
        margin: 0 auto;
        
        @media screen and (max-width:800px){
            padding: 0 1rem;
        }
    }
`;