import styled from 'styled-components';

interface PropsShow {
  show: boolean;
}

export const Container = styled.div`
  
  /* margin-top: 80px; */
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  

  .conten-cards-imoveis {
    max-width: 1200px;
    padding: 0 4%;
    margin: 0 auto;

    .slide {
      gap: 1rem;
    }

    h3 {
      font-weight: bold;
      margin-bottom: 1rem;
    }

    .imoveis {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;

      .card {
        width: 100%;
        height: 230px;
        border-radius: 1rem;
        background-color: rgba(0,0,0, .2);
      }
    }
  }
  

  @media screen and (max-width: 980px) {
    .imoveis {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media screen and (max-width: 760px) {
    .imoveis {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media screen and (max-width: 500px) {
    .imoveis {
      grid-template-columns: 1fr;
    }
  }
`;

export const Content = styled.div<PropsShow>`
  min-height: 1000px;

  .header {
    position: relative;
    
    
    .placeholder-header {
      width: 100%;
      height: 300px;
      object-fit: cover;

      @media screen and (max-width:800px){
        height: auto;
        object-fit: contain;
      }
    }
    .logo-white {
      position: absolute;
      right: 2rem;
      bottom: 2rem;
      width: 200px;

      @media screen and (max-width:800px){
        width: 100px;
        right: 1rem;
        bottom: 1rem;
      }
    }

    
  }
  
  .body {
    max-width: 1200px;
    margin: 5rem auto 0 auto;
    position: relative;

    @media screen and (max-width:800px){
      padding: 0 1rem;
    }

    .avatar {
      color: var(--gray-one);
      width: 200px;
      height: 200px;
      border: 6px solid white;
      position: absolute;
      top: -14rem;
      object-fit: cover;
      
      border-radius: 200px;
      /* box-shadow: 0px 0px 32px rgba(0,0,0, .5); */

      @media screen and (max-width:800px){
        width: 150px;
        height: 150px;
        top: -12rem;
      }
    }

    .title-and-others {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title-and-infos {
        .name-corretor {
          color: var(--color-title-home);
          font-size: 2.2rem;
          margin-top: -14px;
          margin-bottom: 0px;
          letter-spacing: -2px;

          @media screen and (max-width:800px){
            font-size:1.5rem;
            margin: -1.5rem 0 0;
          }
        }
        .class {
          margin:0;
          display: flex;
          align-items: center;
          font-size: 1.2rem;
          color: var(--color-title-home);
          opacity: .8;
          .check-corretor {
            color: var(--primary-green);
            margin-left: 0.5rem;
          }

          @media screen and (max-width:800px){
            font-size: .9rem;
            margin: .3rem 0 0;
          }
        }
        .creci {
          margin-top:12px;
          color: #212e4d;
          font-weight: 700;

          @media screen and (max-width:800px){
            font-size:.7rem;
          }
        }
      }

      .qr-code {
        width: 100px;
        height: 100px;
      }

      .icons-social-midias {
        display: flex;
        gap: 2rem;

        @media screen and (max-width:800px){
          gap:.5rem;
        }

        a {
          text-decoration: none;
        }

        .icons-midias {
          /* border: 1px solid var(--color-title-home);; */
          font-size: 2.5rem;
          padding: 0.5rem;
          color: var(--color-title-home);
          border-radius: 5px;
          cursor: pointer;

          @media screen and (max-width:800px){
            font-size:2rem;
          }
        }
      }
      .avaliation {
        .value-avaliation {
          display: flex;
          align-items: center;
          margin: 0;
          font-size: 3rem;
          font-weight: 700;
          letter-spacing: -3px;
          .star {
            font-size: 2.7rem;
            margin-left: 0.5rem;
          }
        }
        .content-medals {
          display: flex;
          align-items: center;
          justify-content: center;
          gap:0.2rem;
          .ouro {
            font-size: 1.5rem;
            color: var(--gold-primary);
          }
          .prata {
            font-size: 1.5rem;
            color: var(--silver-primary);
          }
          .bronze {
            font-size: 1.5rem;
            color: var(--bronze-primary);
          }
        }
      }
    }
    
  }
  .traco {
    margin: 1rem 4rem;
    width: calc(100% - 8rem);
    height: 1px;
    background-color: var(--gray-one);
    opacity: .6;
  }
  .content-insta {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    .content-images {
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(7,1fr);
      width: 1200px;
      height: 150px;
      gap:0.5rem;
      .primary-box {
        background-color: var(--gray-one);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        p {
          text-align: center;
          font-weight: 600;
          margin-top: 0.5rem;
          color: var(--color-title-home);
        }
        .icon-instagram {
          font-size: 2.5rem;
          color: var(--color-title-home);
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }
    }
  }

  .conten-cards-imoveis-destaque {
        padding: 0;
        width: 1200px;
        margin: 0 auto;
        /* border: 1px solid red; */

        @media screen and (max-width: 800px){
          width: 100%;
          padding: 0 1rem;
        }

        h3 {
            font-weight: bold;
            margin-bottom: 1.5rem;
            margin-top: 1.5rem;
          }

        .conten-cards-imoveis-destaque-carrosel {

          z-index: 2;
          .slide {
            /* gap: 1rem; */
            /* border: 1px solid blue; */
            /* width: 100%; */
            /* margin: 0 2rem; */

            .imoveis {
              gap: 1rem;
              display: flex;
              /* border: 1px solid green; */
              width: 100%;

              .card {
                width: 230px;
                height: 230px;
                border-radius: 1rem;
                background-color: rgba(0,0,0, .2);
              }
            }
          }
          
          
        }
        .container-with-dots {
            z-index: 2;
          }
      }

  .content-cards-and-contact {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    gap:2rem;
    margin-bottom: 3rem;

    @media screen and (max-width:800px){
      width: 100%;
      padding: 0 1rem;
    }

    .left {
      width: 70%;
      /* height: 755px;
      overflow-y: auto; */
      @media screen and (max-width:800px) {
        width: 100%;
      }
      .conten-cards-imoveis {
        padding: 0;
        z-index: 2;
        margin: 0 auto;

        h3 {
          font-weight: bold;
          margin-bottom: 1.5rem;
          margin-top: 1.5rem;
        }
        .conten-cards-imoveis {
          .slide {
            margin:0;
            gap:0;
            .imoveis {
              /* gap: 1rem; */
              display: flex;
              /* width: 100%; */

              .card {
                width: 230px;
                height: 230px;
                border-radius: 1rem;
                background-color: rgba(0,0,0, .2);
              }
            }
          }
        }
      }
    }
    .right {
      width: 30%;

      @media screen and (max-width:800px) {
        width: 100%;
      }

      .desc-right-wrapper {
        position: sticky;
        transition: 1s ease;
        top: ${({ show }) => show ? '1rem' : '6rem'};

        @media screen and (max-width:800px){
          top:1rem;
        }
      }

      .announcement {

        .broker-information {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          margin-top: 1rem;
          padding-left:1.5rem;
          /* border: 1px solid red; */
          height: 100px;

          .image {
            width: 95px;
            height: 95px;
            border-radius: 50%;
            /* background-color: rgba(0,0,0, .1); */
            cursor: pointer;

            img {
              width: 95px;
              height: 95px;
              object-fit: cover;
              border-radius: 50px;
              color: var(--background-button-component);
            }

            .avatar-no-logged {
              font-size: 95px;
              color: var(--background-button-component);
            }

          }

          .name {
            h3{
              margin:0;
              text-align: left;
              cursor: pointer;
              color: var(--background-button-component);
            }

            p {
              color: #777;
              font-size: 0.8rem;
              margin:0.5rem 0 0 0;
              font-weight: 600;
            }

            .phone {
              color: var(--background-button-component);
              font-size: 1rem;
              display: flex;
              align-items: center;

              .avatar-phone {
                font-size: 1.5rem;
                margin: 0 0.2rem 0 0;
              }
            }
          } 
        }
      }
    }
    .right .desc-right-wrapper,
    .simulate-financing,
    .highlights {
      border-radius: 20px;
      border: 1px solid rgba(0,0,0, .1);
      box-shadow: 0px 2px 4px rgba(0,0,0, .09);
      
      h3 {
        text-align: center;
        margin-bottom: 1rem;
        margin-top: 1rem;
      }
    }

    .right .desc-right-wrapper .form,
    .simulate-financing .form {

      input {
        width: 100%;
        height: 52px;
        padding-left: 1rem;
        margin-bottom: 2rem;
        border: 1px solid rgba(0,0,0, .09);
        border-radius: 6px;
        background-color: #f8f8f8;

        &::placeholder {
          color: #ABABAB;
        }
      }
    }

    .right .desc-right-wrapper .form,
    .simulate-financing .form {
      padding: 1rem;

      .calendar {
        border: none;
        margin-bottom: 1rem;
        border-radius: 6px;
        /* background-color: #f8f8f8; */
        .react-calendar__tile--range {
            background-color: var(--background-button-component);
            
        }
        .react-calendar__tile--now {
          background: var(--gray-one);
          
        }
        .react-calendar__tile:enabled:hover{
          background-color: #e6e6e6;
          
        }
        .react-calendar__tile:enabled:focus {
          background-color: var(--background-button-component);
          
        }
      }

      textarea {
        width: 100%;
        min-height: 120px;
        padding-top: .8rem;
        padding-left: 1rem;
        margin-bottom: 1.5rem;
        border: 1px solid rgba(0,0,0, .09);
        border-radius: 6px;
        background-color: #f8f8f8;

        &::placeholder {
          color: #ABABAB;
        }
      }

      h4 {
        margin: 1rem 0 1.5rem 0 ;
      }

    }
    
    .right .highlights {
      padding: 0 1rem 1rem 1rem;
    }
    .right .highlights .highlight-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 220px;
      padding: .7rem;
      border-radius: 15px;
      background-color: rgba(0,0,0, .2);
      box-shadow: inset 0px -55px 32px rgba(0,0,0, .5); 

      .info-highlights {
        display: flex;
        gap: .5rem;

        .orange {
          background-color: #B15636;
        }
        .bold-green {
          background-color: #153B3E;
        }

        .premium, .launch {
          font-size: .8rem;
          color: #fff;
          padding: 5px 8px;
          border-radius: 4px;
        }
      }

      .informations-price {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        color: #fff;

        p {
          font-weight: 900;
        }
      }
    }

    @media screen and (max-width: 820px) {
      flex-direction: column;

      .left {
        width: 100%;
      }
      .right {
        width: 100%;
      }
    }
  }
`;

export const Wrapper = styled.section`
  display: flex;
  gap: 2rem;
  max-width: 1200px;
  padding: 2% 4%;
  margin: 2rem auto 2rem auto;

  .box-left {
    width: 30%;
    max-height: 500px;
    padding: 1rem;
    border-radius: 1rem;
    border: 1px solid rgba(0,0,0, .1);

    .photo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      border-bottom: 1px solid rgba(0,0,0, .1);

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 128px;
        height: 128px;
        margin-bottom: 1rem;
        border-radius: 50%;
        background-color: rgba(0,0,0, .1);
      }

      .check {
        font-size: 0.9rem;
        color: var(--background-button-component);
      }

      .avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 128px;
        margin-bottom: 1rem;
        color: var(--background-button-component);
      }

      p {
        font-size: 1.3rem;
      }
    }

    .validate-profile {
      margin-top: 1rem;

      h4 {
        font-size: 1.3rem;
        font-weight: 600;
        margin: 1rem 0;
      }

      ul {
        list-style: none;

        li {
          font-size: 0.8rem;
          line-height: 28px;
          margin-left: 5px;
        }
      }
    }

  }
  
  .box-right {
    width: 70%;

    .info-member {
      display: flex;
      justify-content: space-between;
    }

    .info-member .info-member-name h1 {
      font-size: 2rem;
      margin-bottom: 5px;
    }

    .info-member .info-member-photo-perfil {
      display: none;
      justify-content: center;
      align-items: center;
      width: 88px;
      height: 88px;
      border-radius: 50%;
      background-color: rgba(0,0,0, .1);
    }

    p {
      color: #777;
    }

    .about {
      padding-bottom: 1rem;
      margin-top: 3rem;
      margin-bottom: 3rem;

      h3 {
        font-size: 1.5rem;
      }

      p {
        color: #444;
      }
    }
  }

  .box-right .announcement {
    width: 100%;
    border-top: 1px solid rgba(0,0,0, .1);

    h4 {
      color: #444;
      font-weight: 600;
      margin-top: 2rem;
    }

    .cards {
      padding-bottom: 2rem;

      .card {
        width: calc(100% - .5rem);
      }

    }
  }

  @media screen and (max-width: 1220px) {
    max-width: 760px;

    .box-left {
      display: none;
    }

    .box-right {
      width: 100%;

      .info-member .info-member-photo-perfil {
        display: flex;
      }
    }
  }
  
`;