import { Container } from "./styles";
import Lottie from 'react-lottie';
import animationData from '../../lotties/404-error.json';

export function Animation404() {

    const defaultOptions = {
        loop: true,
        animationData: animationData,
    }

    return (
        <Container>
            <div className='lottie'>
                <div>
                    <Lottie options={defaultOptions} height={350} width={350} />
                </div>
            </div>
        </Container>

    );
}