import styled from 'styled-components';

export const Container = styled.div`
  .cat-single {
    width: 100%;
    height: 120px;
    padding: .5rem 1rem;
    border-radius: 1rem;
    border: 2px solid rgba(0,0,0, .2);
    transition: all .3s ease;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    &.clicked, &:hover {
      border: 2px solid rgba(0,0,0, .8);
      box-shadow: 0px 2px 4px rgba(0,0,0, .3);
    }
  }
`;