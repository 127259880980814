import { useCallback, useEffect, useState } from "react";
import { Header, ScrollProps } from "../../components/Header";
import { Container } from "./styles";
import React from 'react';
import { GoToTop } from "../../GoToTop";
import { Footer } from "../../components/Footer";
import { useAuth } from "../../context/auth";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";


export function ForgottenPassword() {

    const navigate = useNavigate()
    const MySwal = withReactContent(Swal)
    const { signOut } = useAuth()
    const [scrollTop, setScrollTop] = useState<ScrollProps>({ y: 0, lastY: 0 } as ScrollProps);
    const [show, setShow] = useState(false);
    const [search, setSearch] = useState('');
    const [cpf, setCpf] = useState('');
    // const [creci, setCreci] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [interactionType] = useState((window.location.href.indexOf('esqueci-minha-senha')!==-1 ? 'reset' : 'firstAccess'));

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const scrollTop = event.currentTarget.scrollTop;
        setScrollTop(prevState => { return { y: scrollTop, lastY: prevState.y } })
    }

    const sendEmail = useCallback(async () => {
        setBtnDisabled(true);
        if (!cpf) {
            MySwal.fire({
                icon: 'warning',
                title: 'CPF obrigatório',
                html: `Todos com campos com <span style={{ color: 'red' }} >*</span> são obrigatórios`,
                confirmButtonColor: '#0E2244'
            })
            setBtnDisabled(false);
            return
        }
        // if (!creci) {
        //     MySwal.fire({
        //         icon: 'warning',
        //         title: 'CRECI obrigatório',
        //         html: `Todos com campos com <span style={{ color: 'red' }} >*</span> são obrigatórios`,
        //         confirmButtonColor: '#0E2244'
        //     })
        //     setBtnDisabled(false);
        //     return
        // }

        await axios.post(`${process.env.REACT_APP_API_URL}/reset-password-requisition/`, {
            cpf,
            first_access: (interactionType === 'firstAccess' ? 1 : 0)
            // creci
        }).then(res => {
            if(interactionType === 'firstAccess') {
                navigate('/cadastrar-se/'+res.data.object.uuid);
            } else {
                MySwal.fire({
                    icon: 'success',
                    title: 'Email enviado',
                    text: 'Verifique sua caixa de entrada',
                    confirmButtonColor: '#0E2244'
                }).then(() => {
                    signOut();
                })
            }
        }).catch((e) => {
            let resp;
            try {
                resp = e.response.data.message;
            } catch (error) {
                resp = e?.response?.data;
                if (!resp) {
                    console.log(e);
                    resp = e?.response?.data
                }
            }
            MySwal.fire({
                icon: 'error',
                title: 'Algo de inesperado aconteceu!',
                text: `${resp}`,
                confirmButtonColor: '#0E2244'
            })
            setBtnDisabled(false);
        }
        )
    }, [MySwal, cpf, interactionType, navigate, signOut])

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            <Container onScroll={handleScroll}>
                <Header scroll={scrollTop} show={show} setShow={setShow} search={search} setSearch={setSearch} />

                <div className="content_wrapper">
                    <article className="form_wrapper">

                        <h1 className="title">{interactionType === 'firstAccess' ? 'Primeiro Acesso' : 'Esqueci minha senha'}</h1>

                        <p style={{ fontSize: 14, textAlign:'center' }} >Informe o seu CPF para concluir a solicitação de {interactionType === 'firstAccess' ? 'cadastro' : 'recuperação de senha'}.</p>

                        <div className="form_group">
                            <label htmlFor="">CPF <span style={{ color: 'red' }} >*</span></label>
                            <input type="text" placeholder="xxx.xxx.xxx-xx" value={cpf} onChange={(e) => { setCpf(cpfCnpjMask(e.target.value)) }} autoComplete="off" />
                        </div>

                        {/* <div className="form_group">
                            <label htmlFor="">CRECI <span style={{ color: 'red' }} >*</span></label>
                            <input type="text" placeholder="ABC1234" value={creci} onChange={(e) => { setCreci(e.target.value) }} autoComplete="off" />
                        </div> */}

                        <button disabled={btnDisabled} onClick={() => { sendEmail() }}>
                            {interactionType === 'firstAccess' ? 'Verificar' : 'Enviar e-mail'}
                        </button>

                    </article>
                </div>

                <GoToTop />
                <Footer />
            </Container>
        </>
    )
}



function cpfCnpjMask(text: string) {
    let result = '';
    let mask = '';
    let inputIndex = 0;
    text = text.replaceAll('-', '').replaceAll(' ', '').replaceAll('.', '').replace(/\D/g, '');

    if (text.length <= 11) {
        mask = '###.###.###-##';
    } else {
        mask = '##.###.###/####-##';
    }
    for (let i = 0; i < mask.length; i++) {
        if (inputIndex >= text.length) break;
        if (mask[i] === '#') {
            result += text[inputIndex];
            inputIndex++;
        } else {
            result += mask[i];
        }
    }
    return result;
}