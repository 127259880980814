import { Container } from "./styles";
import Lottie from 'react-lottie';
import animationData from '../../lotties/spinner-2.json';

export function LoadSpinner2() {

    const defaultOptions = {
        loop: true,
        animationData: animationData,
    }

    return (
        <Container>
            <div className='lottie'>
                <div>
                    <Lottie options={defaultOptions} height={100} width={100} />
                </div>
            </div>
        </Container>

    );
}