import styled from 'styled-components';

interface IPropButton {
    w?: string;
    h?: string;
    color?: string;
    bgcolor?: string;
    mb?:string;
}

export const Container = styled.div<IPropButton>`
  
  button {
    width: ${({ w }) => w ? w : '100%'};
    height: ${({ h }) => h ? h : '100%'};
    color: ${({ color }) => color ? color : '#000'};
    margin-bottom: ${({ mb }) => mb ? mb : '0'};
    font-size: 1rem;
    font-weight: 600;
    border: 0;
    cursor: pointer;
    border-radius: 8px;
    background-color: ${({ bgcolor }) => bgcolor ? bgcolor : 'rgba(0,0,0, .08)'};
    transition: all .4s ease;

    &:hover {
        opacity: .9;
    }
  }
`;