import styled from "styled-components";


export const Container = styled.div`
    /* background-color: #0d2345; */

    div.content_wrapper {
        width: 85vw;
        min-height: calc(100vh - 80px);
        margin: 0px 7.5vw;
        display: flex;
        justify-content: center;
        align-items: center;

        article.form_wrapper {
            background: white;
            padding: 58px 28px;
            box-shadow: 1px 1px 12px rgba(0,0,0,.1);
            border-radius: 12px;
            width: 400px;

            h1.title {
                text-align: center;
                margin-bottom: 28px;
            }

            div.form_group {
                display: block !important;
                margin-bottom: 12px;

                label {
                    width: 100%;
                    text-align: left;
                    font-size: 14px;
                }
                input {
                    width: 100%;
                    border: 1px solid #e4e4e4;
                    padding: 12px;
                    margin: 6px 0px;
                    border-radius: 5px;
                    box-shadow: 0px 0px 4px rgba(0,0,0,.05) inset;
                    font-size: 14px;
                    background: #f8f8f8;
                    ::placeholder {
                        color: rgba(0,0,0,.4);
                    }
                }

                .input-div {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    border: 1px solid #e4e4e4;
                    /* padding: 12px; */
                    /* margin: 6px 0px; */
                    border-radius: 5px;
                    box-shadow: 0px 0px 4px rgba(0,0,0,.05) inset;
                    font-size: 14px;
                    background: #f8f8f8;
                    ::placeholder {
                        color: rgba(0,0,0,.4);
                    }

                    .input-div-eye {
                        border: none;
                        background-color: transparent;
                        width: 80%;
                        margin: 0;
                        box-shadow: none;
                    }

                    .button-eye {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: none;
                        background-color: transparent;
                        width: 20%;
                        margin:0;
                    }
                }

                
            }

            button {
                background: #0d2345;
                color: white;
                width: 100%;
                border-radius: 5px;
                padding: 12px;
                border: none;
                margin-top: 24px;
                :disabled {
                    opacity: .6;
                    cursor: wait;
                }
            }

            .invalid {
                color: red;
            }
            .valid {
                color: #07c100;
            }
        }
    }

`;
