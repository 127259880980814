import React from 'react';

import {
  Container,
} from './styles';
import { Animation404 } from '../../components/Animation404';
import { Door404 } from '../../components/Door404';

export function NotFound() {
  return(
    <Container>
      <Animation404 />
      <Door404 />
        <p>Desculpe, página não encontrada!</p>
    </Container>
  );
}
