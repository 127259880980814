import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: auto;
    padding: 3rem 0 0 0;
    background: var(--background-footer);
`;

export const Content = styled.div`
    padding-left:4%;
    padding-right:4%;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    margin-bottom: 2rem;

    @media screen and (max-width: 1276px) {
        grid-template-columns: repeat(2,1fr);
        gap: 3rem;
    }

    @media screen and (max-width: 810px) {
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .col-1 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
            width: 200px;
            margin: 0 0 2rem 0;
        }
        .endereco, .contato-tel {
            font-size: 0.9rem;
            margin: 0 0 1rem 0;
            text-align: center;
        }
        
    }

    .col-2 {
        h3 {
            font-weight: bold;
            text-align: center;
        }
        .content-links {
            display: flex;
            flex-direction: column;
            gap: 0.7rem;
            margin-top: 1rem;
            .links {
                text-decoration: none;
                color: var(--color-font-footer);
                font-size: 0.9rem;
                text-align: center;
                transition: 0.3s;

                &:hover {
                    color: #000
                }
            }
        }
        
    }

    .col-3 {
        h3 {
            font-weight: bold;
            text-align: center;
        }
        .content-col-3 {
            display: flex;
            flex-direction: column;
            gap: -10px;
            margin-top: 1rem;
            .times {
                text-decoration: none;
                color: var(--color-font-footer);
                font-size: 0.9rem;
                text-align: center;
                transition: 0.3s;

                &:hover {
                    color: #000
                }
            }
        }
    }

    .col-4 {
        h3 {
            font-weight: bold;
            text-align: center;
        }
        .content-col-4 {
            display: flex;
            flex-direction: column;
            gap:1rem;
            .icon-and-text {
                display: flex;
                align-items: center;
                gap:1rem;
                .icon-1{
                    color: white;
                    background-color: var(--background-button-component);
                    width: 40px;
                    height: 40px;
                    padding: 0.5rem;
                    border-radius: 50%;
                }
                .icon-2{
                    color: white;
                    background-color: var(--color-font-footer);
                    width: 40px;
                    height: 40px;
                    padding: 0.5rem;
                    border-radius: 50%;
                }
                .infos-address {
                    .title {
                        color: var(--color-font-footer);
                        font-size: 0.9rem;
                        text-align: left;
                        transition: 0.3s;
                        margin: 0;
                        font-weight:600;
                    }
                    p {
                        color: var(--color-font-footer);
                        font-size: 0.9rem;
                        text-align: left;
                        transition: 0.3s;
                        margin: 0;
                    }
                }
            }
        }
    }
    
`;

export const ContentFinally = styled.div`
    background: var(--background-button-component);
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding-right: 4%;
    padding-left: 4%;
        
        .icons {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
            font-size: 1.3rem;
            color: #fff;
        }

        .direitos-reservados {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 0.5rem;
            p {
                font-size: 0.8rem;
                color: #fff;
                margin: 0;

                @media screen and (max-width: 510px) {
                    font-size: 0.6rem;
                }
            }
        }
    

`;