import styled from "styled-components";

export const ContentWrapper = styled.div`
    border-radius: 18px;

    iframe {
        border-radius: 8px;
        max-height: 1600px;
        min-height: 600px;
    }
`;