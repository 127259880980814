import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  html, textarea, input, p, span, select, div{
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    scroll-behavior: smooth;
  }

  h1, h2, h3, h4, h5 {
    font-family: 'Poppins';
  }

  button {
    cursor: pointer;
  }

  .swal2-container  {
    zoom: .8 !important;
  }

  :root {
    --bg-color: #fff;

    --wrapper-width: 1240px;
    --gray-one: #CACACA;
    --gold-primary: #ffd700;
    --silver-primary: #c0c0c0;
    --bronze-primary: #9c5221;
    --background-input: #f9f9f6;
    --background-footer: #EBEBEB;
    --color-font-footer: #888A88;
    --color-placeholder-input: #ABABAB;
    --background-button-component: #0E2244;
    --primary-green: #0fae3a;
    --color-title-home: #606060;
    --primary-gold: #ffb727;
    --primary-red: #da3754;
    --secondary-blue: #5ecde0;
  }

  ul::-webkit-scrollbar {
    width: 7px !important;
    height: 10px !important;  
    border: 1px solid white !important;
  }
  ::-webkit-scrollbar {
      width: 12px !important;
      height: 10px !important;
      cursor: pointer;
  }

  ::-webkit-scrollbar-track {
      background: rgb(255, 255, 255, .4) !important;
      border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
      background-color: #c1c1c1 !important;
      border-radius: 10px !important;
      width: 10px !important;
      border: 2px solid rgb(255, 255, 255, .4) !important;
  }

  body::-webkit-scrollbar-track {
      background: white !important;
      border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
      border: 2px solid white !important;
  }


  body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }
  html, body, #root {
    height: 100%;
  }

  .react-modal-overlay {
        /* background: rgba(0, 0, 0, 0.5); */
        position: fixed;
        top: 80px;
        right: 0;
        left: 0;
        
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
    }

    .react-modal-content {
        width: 100%;
        width: 650px;
        height: 200px;
        background: gray;
        opacity: 0.9;
        padding: 1rem;
        position: relative;
        border-radius: 0.24rem;
        color: #fff;
    }

    .react-modal-close {
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
        border: 0;
        background: transparent;
        cursor: pointer;

        transition: filter 0.2s;

        &:hover {
            filter: brightness(0.7);
        }
    }

    .hidden {
      top:-80px !important;
    }
`;