import { GoogleMap, Marker, OverlayView, StreetViewPanorama, useJsApiLoader } from "@react-google-maps/api";
import React, {useRef} from "react";
import { IPropsMaps } from "../../types";
import { Container } from "./styles";

export function MapsGoogleStreetView({position, height, zoom} : IPropsMaps) {
    const markRef = useRef({Marker});
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: 'AIzaSyCDsc9pmM4xfjWcIyNokhgbrd5SYQDiz7o'
    })
    // console.log(position);

    return (
        <Container style={{ height: height }}>
            {
                isLoaded ? (
                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '100%', }}
                        center={{
                            lat: Number(position.latCenter),
                            lng: Number(position.lngCenter)
                        }}
                        zoom={zoom}
                    >
                        <Marker
                            // ref={markRef}
                            position={{
                                lat: Number(position.latMarker),
                                lng: Number(position.lngMarker)
                            }}
                            // key={position.id + position.lat + position.lng}
                            // draggable={true}
                            // onDragEnd={(a) => {
                            //     const {latLng} = a;
                            //     // console.log(latLng.lat(), latLng.lng())
                            //     setPosition({lat: latLng.lat(), lng: latLng.lng()})
                            // }}
                        />
                        <StreetViewPanorama 
                            options={{
                                position:{
                                    lat:Number(position.latMarker),
                                    lng:Number(position.lngMarker)
                                },
                                visible:true
                            }}
                        />
                            
                        
                        {/* <OverlayView mapPaneName="overlayLayer" position={{
                                lat: Number(position.lat),
                                lng: Number(position.lng)
                            }} /> */}
                    </GoogleMap>
                ) : (
                    <></>
                )

            }
        </Container>
    );
}