import { useCallback, useEffect, useState } from "react";
import { Header, ScrollProps } from "../../components/Header";
import { Container } from "./styles";
import React from 'react';
import { GoToTop } from "../../GoToTop";
import { Footer } from "../../components/Footer";
import { useAuth } from "../../context/auth";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
// import { tasks as taskList, employees } from './data';


interface IReset {
    uuid: string;
    corretor: {
        email_social: string;
    }
}


export function ResetPassword() {

    const MySwal = withReactContent(Swal)
    const { api, signOut } = useAuth()
    const [scrollTop, setScrollTop] = useState<ScrollProps>({ y: 0, lastY: 0 } as ScrollProps);
    const [show, setShow] = useState(false);
    const [search, setSearch] = useState('');
    const [resetData, setResetData] = useState({} as IReset);
    const { uuid } = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
    const [email, setEmail] = useState('');
    const [lengthValid, setLengthValid] = useState('invalid');
    const [equalValid, setEqualValid] = useState('invalid');
    const [letterValid, setLetterlValid] = useState('invalid');
    const [numberValid, setNumberlValid] = useState('invalid');
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);
    const [viewPasswordConfirmation, setViewPasswordConfirmation] = useState(false)
    const [interactionType] = useState((window.location.href.indexOf('redefinir-senha') !== -1 ? 'reset' : 'register'));


    const loadData = useCallback(async () => {
        await api.get(`/reset-password-requisition/${uuid}`).then(res => {
            setResetData(res.data);
        }).catch((e) => { console.log(e); signOut() })
    }, [api, signOut, uuid]);


    const validation = useCallback(() => {
        if (newPassword.length < 6) {
            setLengthValid('invalid');
        } else {
            setLengthValid('valid');
        }
        if (!(newPassword === newPasswordConfirmation)) {
            setEqualValid('invalid');
        } else {
            setEqualValid('valid');
        }
        if (!newPassword.replace(/[^a-zA-ZÀ-ÿ]/g, '')) {
            setLetterlValid('invalid');
        } else {
            setLetterlValid('valid');
        }
        if (!newPassword.replace(/\D/g, '')) {
            setNumberlValid('invalid');
        } else {
            setNumberlValid('valid');
        }
    }, [newPassword, newPasswordConfirmation])


    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const scrollTop = event.currentTarget.scrollTop;
        setScrollTop(prevState => { return { y: scrollTop, lastY: prevState.y } })
    }


    const submitReset = useCallback(async () => {
        if (!email && (interactionType === 'register')) {
            MySwal.fire({
                icon: 'error',
                title: 'Email obrigatório',
                confirmButtonColor: '#0E2244'
            })
            return
        }
        if (newPassword !== newPasswordConfirmation) {
            MySwal.fire({
                icon: 'error',
                title: 'A senhas não coincidem',
                confirmButtonColor: '#0E2244'
            })
            return
        }
        if ((lengthValid === 'invalid') || (equalValid === 'invalid') || (letterValid === 'invalid') || (numberValid === 'invalid')) {
            MySwal.fire({
                icon: 'warning',
                title: 'A senha não preenche os requisitos',
                confirmButtonColor: '#0E2244'
            })
            return
        }

        setBtnDisabled(true);

        await api.post(`/reset-password-submit/`, {
            uuid: uuid,
            email: email,
            first_access: (interactionType === 'register' ? 1 : 0),
            password: newPassword,
            passwordConfirmation: newPasswordConfirmation,
        }).then(res => {
            // console.log(res.data);
            MySwal.fire({
                icon: 'success',
                title: 'Feito!',
                text: interactionType === 'register' ? 'Cadastro realizado com sucesso.' : 'Sua senha foi redefinida com sucesso.',
                confirmButtonColor: '#0E2244'
            }).then(() => {
                signOut();
            })
        }).catch((e) => {
            setBtnDisabled(false);
            let resp;
            try {
                resp = JSON.parse(e?.response?.data ? e?.response?.data : '{}');
                if (resp[0] !== undefined) {
                    resp = resp[0].message;
                }

            } catch (error) {
                resp = e?.response?.data;
            }

            console.log('resp', resp);
            MySwal.fire({
                icon: 'error',
                title: 'Algo de inesperado aconteceu!',
                text: `${resp}`,
                confirmButtonColor: '#0E2244'
            })
        }
        )
    }, [MySwal, api, email, equalValid, interactionType, lengthValid, letterValid, newPassword, newPasswordConfirmation, numberValid, signOut, uuid])


    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        validation();
    }, [newPassword, newPasswordConfirmation, validation]);


    return (
        <>
            <Container onScroll={handleScroll}>
                <Header scroll={scrollTop} show={show} setShow={setShow} search={search} setSearch={setSearch} />

                <div className="content_wrapper">
                    <article className="form_wrapper">

                        <h1 className="title" >{interactionType === 'reset' ? 'Redefinir Senha' : 'Cadastre-se'}</h1>

                        {interactionType === 'reset' ?
                            <div>
                                <span>Redefinir senha para: <span style={{ color: '#0d2345', fontWeight: 500 }} >{resetData?.corretor?.email_social}</span></span>
                                <br />
                                <br />
                            </div>
                            :
                            <div>
                                <span>Informe um email válido e uma senha de acesso.</span>
                                <br />
                                <br />
                            </div>}


                        {interactionType === 'register' ?
                            <div className="form_group">
                                <label htmlFor="">E-mail: <span style={{ color: 'red' }} >*</span></label>
                                <input type="email" onChange={(e) => { setEmail(e.target.value); }} placeholder="exemplo@mail.com" autoComplete="off" />
                            </div>
                            : ''}

                        <div className="form_group">
                            <label htmlFor="">Nova Senha: <span style={{ color: 'red' }} >*</span></label>
                            <div className="input-div">
                                <input className="input-div-eye" type={viewPassword ? "text" : "password"} name="" onChange={(e) => { setNewPassword(e.target.value); }} placeholder="ABCdef@1234!#@" autoComplete="off" />
                                <button className="button-eye" onClick={()=>setViewPassword(!viewPassword)}>{viewPassword ? <FaRegEye color="#000" /> : <FaRegEyeSlash color="#000" /> }</button>
                            </div>
                        </div>

                        <div className="form_group">
                            <label htmlFor="">Confirme a Nova Senha: <span style={{ color: 'red' }} >*</span></label>
                            <div className="input-div">
                                <input className="input-div-eye" type={viewPasswordConfirmation ? "text" : "password"} name="" onChange={(e) => { setNewPasswordConfirmation(e.target.value); }} placeholder="ABCdef@1234!#@" autoComplete="off" />
                                <button className="button-eye" onClick={()=>setViewPasswordConfirmation(!viewPasswordConfirmation)}>{viewPasswordConfirmation ? <FaRegEye color="#000" /> : <FaRegEyeSlash color="#000" /> }</button>
                            </div>
                        </div>

                        {newPassword ?
                            <div style={{ marginTop: -8 }} className="">
                                <span style={{ fontWeight: 500, fontSize: 14 }} className={`${lengthValid}`}>
                                    <span style={{ fontWeight: 600, fontSize: 14 }} >{lengthValid === 'valid' ? '✓' : '✗'}</span> pelo menos 6 caracteres
                                </span>
                                <br />
                                <span style={{ fontWeight: 500, fontSize: 14 }} className={`${numberValid}`}>
                                    <span style={{ fontWeight: 600, fontSize: 14 }} >{numberValid === 'valid' ? '✓' : '✗'}</span> conter pelo menos um número
                                </span>
                                <br />
                                <span style={{ fontWeight: 500, fontSize: 14 }} className={`${letterValid}`}>
                                    <span style={{ fontWeight: 600, fontSize: 14 }} >{letterValid === 'valid' ? '✓' : '✗'}</span> conter pelo menos uma letra
                                </span>
                                <br />
                                <span style={{ fontWeight: 500, fontSize: 14 }} className={`${equalValid}`} >
                                    <span style={{ fontWeight: 600, fontSize: 14 }} >{equalValid === 'valid' ? '✓' : '✗'}</span> senhas coincidem
                                </span>
                            </div>
                            : ''}

                        <button onClick={() => { submitReset() }} disabled={btnDisabled}>
                            Salvar
                        </button>

                    </article>
                </div>

                <GoToTop />
                <Footer />
            </Container>
        </>
    )
}
